import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  fetchQaPagesOrderList,
  fetchChangeOrderItems,
} from "../../actions/Admin/qa";
import { Fragment } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import Breadcrumb from "../../components/Admin/Reusable/Breadcrumb";

const SortableList = ({ dataList, dispatch }) => {
  const [items, setItems] = useState(dataList);

  // Koristite useEffect za praćenje promene dataList i ažuriranje items
  useEffect(() => {
    setItems(dataList);
  }, [dataList]);

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedItems = [...items];
    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);

    dispatch(fetchChangeOrderItems(reorderedItems));

    setItems(reorderedItems);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            className="sortable-list"
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{
              background: snapshot.isDraggingOver ? "lightblue" : "lightgrey",
            }}
          >
            {items.map((item, index) => (
              <Draggable key={item.anId} draggableId={item.anId} index={index}>
                {(provided, snapshot) => (
                  <div
                    className="sortable-item"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{
                      ...provided.draggableProps.style,
                      background: snapshot.isDragging ? "#e0e0e0" : "#f2f2f2",
                    }}
                  >
                    <div>{item.acTitle}</div>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const QaSorting = () => {
  const dispatch = useDispatch();

  const { dataPagesList } = useSelector((state) => state.qaReducer);
  const [anCategory, setAnCategory] = useState(0);
  const [sortedDataList, setSortedDataList] = useState(dataPagesList);
  const [sortedDataListEng, setSortedDataListEng] = useState(dataPagesList);

  const handleSubmit = (event, categoryId) => {
    setAnCategory(categoryId);
    event.preventDefault();
    if (categoryId > 0) {
      dispatch(fetchQaPagesOrderList(categoryId));
    } else {
      setSortedDataList([]);
      setSortedDataListEng([]);
    }
  };

  useEffect(() => {
    const filteredDataList = dataPagesList.filter(
      (item) => item.anLanguage === "1"
    );
    const filteredDataListEng = dataPagesList.filter(
      (item) => item.anLanguage === "2"
    );

    setSortedDataList(filteredDataList);
    setSortedDataListEng(filteredDataListEng);
  }, [dataPagesList]);

  useEffect(() => {
    if (anCategory > 0) {
      dispatch(fetchQaPagesOrderList(anCategory));
    } else {
      setSortedDataList([]);
      setSortedDataListEng([]);
    }
  }, [dispatch, anCategory]);

  return (
    <Fragment>
      <Breadcrumb title="Qa sortiranje" type="qa-sort" />
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h1 className="card-title mb-0 flex-grow-1">
                Sortiranje pitanja
              </h1>
            </div>
            <div className="card-body">
              <form noValidate>
                <div className="row">
                  <div className="col-lg-6 col-xs-12">
                    <FormControl fullWidth className="mb-4">
                      <InputLabel id="demo-simple-select-label">
                        Kategorija
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={anCategory}
                        label="Kategorija"
                        name="anCategory"
                        onChange={(e) => handleSubmit(e, e.target.value)}
                        size="small"
                      >
                        <MenuItem value="0">Odaberite kategoriju</MenuItem>
                        <MenuItem value="1">Cyber security pitanja</MenuItem>
                        <MenuItem value="2">SOC</MenuItem>
                        <MenuItem value="3">
                          Penetraciono testiranje landing strana
                        </MenuItem>
                        <MenuItem value="4">
                          Tactical Threat Intelligence landing stranica
                        </MenuItem>
                        <MenuItem value="5">
                          IBM Guardium Landing strana
                        </MenuItem>
                      </Select>
                    </FormControl>

                    {/* <Button type="submit" variant="contained" color="success">
                      Prikaži pitanja
                    </Button> */}
                  </div>
                </div>
              </form>
              <div className="col-12">
                <div className="row">
                  <div className="col-lg-6 col-xs-12">
                    {sortedDataList?.length > 0 && (
                      <SortableList
                        dataList={sortedDataList}
                        dispatch={dispatch}
                      />
                    )}
                  </div>
                  <div className="col-lg-6 col-xs-12">
                    {sortedDataList?.length > 0 && (
                      <SortableList
                        dataList={sortedDataListEng}
                        dispatch={dispatch}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default QaSorting;
