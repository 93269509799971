import React from "react";
import BlogItem from "./BlogItem";
import { fetchBlogListItems } from "../../actions/Site/global";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import i18next from "i18next";
import { baseUrl } from "../../utils";

const BlogItems = () => {
  const dispatch = useDispatch();
  const { dataPagesListBlogAll } = useSelector((state) => state.globalReducer);

  const [currentLanguage, setCurrentLanguage] = useState(i18next.language);
  const [startLang, setStartLang] = useState(0);

  useEffect(() => {
    dispatch(fetchBlogListItems());

    const languageChangeHandler = (newLanguage) => {
      setCurrentLanguage(newLanguage);
    };

    // Pretplatite se na događaj promene jezika
    i18next.on("languageChanged", languageChangeHandler);

    setStartLang(1);

    // Očistite pretplatu kada se komponenta odmontira
    return () => {
      i18next.off("languageChanged", languageChangeHandler);
    };
  }, [dispatch]);

  useEffect(() => {
    if (startLang > 0) dispatch(fetchBlogListItems());
  }, [currentLanguage]);

  return (
    <div className="blog-block-content-holder">
      <div className="container-fluid">
        <div className="row">
          <div className="blog-block-body">
            {dataPagesListBlogAll?.map((item, key) => (
              <BlogItem
                item={item}
                key={key}
                image={baseUrl + "images/" + item.acImage}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogItems;
