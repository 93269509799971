import React, { useEffect, useState } from "react";

const HeroAi = ({
  title,
  img,
  color = "#fff",
  maxWidth = "normal",
  subtitle,
  text,
}) => {
  const imageStyle = {
    backgroundImage: `url(${require(`../../../assets/Media/${img}`)})`,
  };

  const [hasReachedSection, setHasReachedSection] = useState(
    "show-first-animation"
  );

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    const threshold = 50;
    if (scrollTop < threshold) {
      setHasReachedSection("show-animation");
    } else {
      setHasReachedSection("");
    }
  };

  return (
    <div className="service-hero-holder-content-ai" style={imageStyle}>
      <div className="container-fluid">
        <div className="row">
          <div
            className={`service-hero-holder ${hasReachedSection} ${
              maxWidth == "large" && "hero-title-large"
            }`}
          >
            <span class={`block ${hasReachedSection}`}></span>
            <h3>{subtitle}</h3>
            <h1
              style={{ color: `${color}` }}
              dangerouslySetInnerHTML={{
                __html: title,
              }}
            ></h1>
            <div className="service-hero-info-line">
              <div className="service-hero-info-image">
                <img
                  src="/images/logo-ai.png"
                  className="img-fluid"
                  alt="Oktacron"
                />
              </div>
              <div className="service-hero-info-line-center"></div>
              <div className="service-hero-info-text">
                <span>{text}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroAi;
