import React, { useState } from "react";
import Button from "../../Reusable/Button";

const OurServiceListItems = ({ t }) => {
  const [showActive, setShowActive] = useState(null);
  return (
    <div className="service-our-service-item-holder-content">
      <div className="container-fluid">
        <div className="row">
          <div className="service-our-service-item-holder">
            <div className="service-our-service-item" id="cyberGuardian">
              <div
                className="container-serive-image-text-block container-serive-image-text-block-first-child"
                style={{ backgroundImage: "url(/images/service-1.png)" }}
              >
                <h1>{t("services.guardian.title")}</h1>
                <p>{t("services.guardian.subtitle")}</p>
              </div>
              <div className="content-our-service-content-holder">
                <p>{t("services.guardian.description")}</p>

                <div className="guardian-holder-content">
                  <div className="guardian-holder-content-left">
                    <ul>
                      <li className={`${showActive === 1 && "active"}`}>
                        {t("services.guardian.li-1")}
                      </li>

                      <li className={`${showActive === 2 && "active"}`}>
                        {t("services.guardian.li-2")}
                      </li>

                      <li className={`${showActive === 3 && "active"}`}>
                        {t("services.guardian.li-3")}
                      </li>

                      <li className={`${showActive === 4 && "active"}`}>
                        {t("services.guardian.li-4")}
                      </li>

                      <li className={`${showActive === 5 && "active"}`}>
                        {t("services.guardian.li-5")}
                      </li>

                      <li className={`${showActive === 6 && "active"}`}>
                        {t("services.guardian.li-6")}
                      </li>
                    </ul>
                  </div>
                  <div className="guardian-holder-content-right">
                    <div className="guardian-item-1-text">
                      {t("vciso.text-1")}
                    </div>
                    <div
                      className="guardian-item-2-text guardian-text-item"
                      onMouseEnter={() => setShowActive(1)}
                      onMouseLeave={() => setShowActive(null)}
                    >
                      {t("vciso.text-2")}
                    </div>
                    <div
                      className="guardian-item-3-text guardian-text-item"
                      onMouseEnter={() => setShowActive(3)}
                      onMouseLeave={() => setShowActive(null)}
                    >
                      {t("vciso.text-3")}
                    </div>
                    <div
                      className="guardian-item-4-text guardian-text-item"
                      onMouseEnter={() => setShowActive(6)}
                      onMouseLeave={() => setShowActive(null)}
                    >
                      {t("vciso.text-4")}
                    </div>
                    <div
                      className="guardian-item-5-text guardian-text-item"
                      onMouseEnter={() => setShowActive(2)}
                      onMouseLeave={() => setShowActive(null)}
                    >
                      {t("vciso.text-5")}
                    </div>
                    <div
                      className="guardian-item-6-text guardian-text-item"
                      onMouseEnter={() => setShowActive(4)}
                      onMouseLeave={() => setShowActive(null)}
                    >
                      {t("vciso.text-6")}
                    </div>
                    <div
                      className="guardian-item-7-text guardian-text-item"
                      onMouseEnter={() => setShowActive(5)}
                      onMouseLeave={() => setShowActive(null)}
                    >
                      {t("vciso.text-7")}
                    </div>
                    {/* <img
                      src="/images/guardian-image.svg"
                      className="img-fluid"
                      alt="Oktacron"
                    /> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="service-our-service-item" id="penetrationTesting">
              <div
                className="container-serive-image-text-block"
                style={{ backgroundImage: "url(/images/service-2.png)" }}
              >
                <h1>{t("services.penetration.title")}</h1>
                {t("services.penetration.subtitle")
                  .split("\n")
                  .map((tekst, indeks) => (
                    <p key={indeks}>{tekst}</p>
                  ))}
                <div className="shield-content-holder">
                  <span>{t("home.endToEnd.shield1_title")}</span>
                  <img
                    src="/images/shield1.png"
                    alt="Oktacron"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="content-our-service-content-holder">
                <h2>{t("services.penetration.title2")}:</h2>

                <div className="penetration-block-items-list">
                  <div className="penetration-block-list-item">
                    <div className="penetration-item-image">
                      <img
                        src="/images/penetration-1.png"
                        className="img-fluid"
                        alt="Oktacron"
                      />
                    </div>
                    <div className="penetration-item-title">
                      {t("services.penetration.box-1-title")}
                    </div>
                    <div className="penetration-item-desc">
                      {t("services.penetration.box-1-desc")}
                    </div>
                  </div>
                  <div className="penetration-block-list-item">
                    <div className="penetration-item-image">
                      <img
                        src="/images/penetration-2.png"
                        className="img-fluid"
                        alt="Oktacron"
                      />
                    </div>
                    <div className="penetration-item-title">
                      {t("services.penetration.box-2-title")}
                    </div>
                    <div className="penetration-item-desc">
                      {t("services.penetration.box-2-desc")}
                    </div>
                  </div>
                  <div className="penetration-block-list-item">
                    <div className="penetration-item-image">
                      <img
                        src="/images/penetration-3.png"
                        className="img-fluid"
                        alt="Oktacron"
                      />
                    </div>
                    <div className="penetration-item-title">
                      {t("services.penetration.box-3-title")}
                    </div>
                    <div className="penetration-item-desc">
                      {t("services.penetration.box-3-desc")}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="service-our-service-item" id="riskAssessment">
              <div
                className="container-serive-image-text-block"
                style={{ backgroundImage: "url(/images/service-3.png)" }}
              >
                <h1>{t("services.risk.title")}</h1>
                <p>{t("services.risk.subtitle")}</p>
                <div className="shield-content-holder">
                  <span>{t("home.endToEnd.shield1_title")}</span>
                  <img
                    src="/images/shield1.png"
                    alt="Oktacron"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="content-our-service-content-holder">
                {t("services.risk.description")
                  .split("\n")
                  .map((tekst, indeks) => (
                    <p key={indeks}>{tekst}</p>
                  ))}
              </div>
            </div>

            <div
              className="service-our-service-item"
              id="protectionOfDatabases"
            >
              <div
                className="container-serive-image-text-block"
                style={{ backgroundImage: "url(/images/service-4.png)" }}
              >
                <h1>{t("services.database.title")}</h1>
                <p>{t("services.database.subtitle")}</p>
                <div className="shield-content-holder">
                  <span>{t("home.endToEnd.shield2_title")}</span>
                  <img
                    src="/images/shield2.png"
                    alt="Oktacron"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="content-our-service-content-holder">
                <p>{t("services.database.description")}</p>

                <Button
                  nameClass={`custom-services-button`}
                  text={t("services.database.button")}
                  link={`ibm-guardium`}
                />
              </div>
            </div>

            <div
              className="service-our-service-item"
              id="vulnerabilityManagement"
            >
              <div
                className="container-serive-image-text-block"
                style={{ backgroundImage: "url(/images/service-5.png)" }}
              >
                <h1>{t("services.vulnerability.title")}</h1>
                <p>{t("services.vulnerability.subtitle")}</p>
                <div className="shield-content-holder">
                  <span>{t("home.endToEnd.shield2_title")}</span>
                  <img
                    src="/images/shield2.png"
                    alt="Oktacron"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="content-our-service-content-holder">
                <p>{t("services.vulnerability.description")}</p>

                <Button
                  nameClass={`custom-services-button`}
                  link={`qualys`}
                  text={t("services.vulnerability.button")}
                />
              </div>
            </div>

            <div className="service-our-service-item" id="soc">
              <div
                className="container-serive-image-text-block"
                style={{ backgroundImage: "url(/images/service-6.png)" }}
              >
                <h1>{t("services.soc.title")}</h1>
                <p>{t("services.soc.subtitle")}</p>
                <div className="shield-content-holder">
                  <span>{t("home.endToEnd.shield3_title")}</span>
                  <img
                    src="/images/shield3.png"
                    alt="Oktacron"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="content-our-service-content-holder">
                <div className="content-our-service-content-holder-soc">
                  <div className="content-our-service-content-holder-soc-left">
                    {t("services.soc.description")
                      .split("\n")
                      .map((tekst, indeks) => (
                        <p key={indeks}>{tekst}</p>
                      ))}

                    <Button
                      nameClass={`custom-services-button`}
                      text={`UPOZNAJTE NAŠ SOC`}
                      link={`soc`}
                    />
                  </div>
                  <div className="content-our-service-content-holder-soc-right">
                    {/* <img
                      src="/images/soc-krugovi.png"
                      className="img-fluid"
                      alt="Oktacron"
                    /> */}

                    <div className="soc-section-content-left">
                      <div className="soc-section-content-left-items">
                        <div className="soc-section-content-left-item-1">
                          {t("socHome.text-1")}
                        </div>
                        <div className="soc-section-content-left-item soc-section-content-left-item-2">
                          {t("socHome.text-2")}
                        </div>
                        <div className="soc-section-content-left-item soc-section-content-left-item-3">
                          {t("socHome.text-3")}
                        </div>
                        <div className="soc-section-content-left-item soc-section-content-left-item-4">
                          {t("socHome.text-4")}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="service-our-service-item" id="digitalTraps">
              <div
                className="container-serive-image-text-block"
                style={{ backgroundImage: "url(/images/service-7.png)" }}
              >
                <h1>{t("services.digital.title")}</h1>

                <p
                  dangerouslySetInnerHTML={{
                    __html: t("services.digital.subtitle"),
                  }}
                />
                <div className="shield-content-holder">
                  <span>{t("home.endToEnd.shield4_title")}</span>
                  <img
                    src="/images/shield4.png"
                    alt="Oktacron"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="content-our-service-content-holder">
                <p>{t("services.digital.description")}</p>
              </div>
            </div>

            <div
              className="service-our-service-item"
              id="tacticalThreatIntelligence"
            >
              <div
                className="container-serive-image-text-block"
                style={{ backgroundImage: "url(/images/service-8.png)" }}
              >
                <h1>{t("services.tactical.title")}</h1>

                <p
                  dangerouslySetInnerHTML={{
                    __html: t("services.tactical.subtitle"),
                  }}
                />
                <div className="shield-content-holder">
                  <span>{t("home.endToEnd.shield4_title")}</span>
                  <img
                    src="/images/shield4.png"
                    alt="Oktacron"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="content-our-service-content-holder">
                <p
                  dangerouslySetInnerHTML={{
                    __html: t("services.tactical.description"),
                  }}
                />
              </div>
            </div>

            <div className="service-our-service-item" id="incidentManagement">
              <div
                className="container-serive-image-text-block"
                style={{ backgroundImage: "url(/images/service-9.png)" }}
              >
                <h1>{t("services.incident.title")}</h1>
                <p>{t("services.incident.subtitle")}</p>
                <div className="shield-content-holder">
                  <span>{t("home.endToEnd.shield4_title")}</span>
                  <img
                    src="/images/shield4.png"
                    alt="Oktacron"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="content-our-service-content-holder">
                <p
                  dangerouslySetInnerHTML={{
                    __html: t("services.incident.description"),
                  }}
                />

                <Button
                  nameClass={`custom-services-button`}
                  text={t("services.incident.button")}
                  link={`irms`}
                />
              </div>
            </div>

            <div
              className="service-our-service-item"
              id="incidentRecoverySupport"
            >
              <div
                className="container-serive-image-text-block"
                style={{ backgroundImage: "url(/images/service-10.png)" }}
              >
                <h1>{t("services.recovery.title")}</h1>
                <p>{t("services.recovery.subtitle")}</p>
                <div className="shield-content-holder">
                  <span>{t("home.endToEnd.shield5_title")}</span>
                  <img
                    src="/images/shield5.png"
                    alt="Oktacron"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="content-our-service-content-holder">
                <p>{t("services.recovery.description")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurServiceListItems;
