import "./App.css";

import { Route, Routes } from "react-router-dom";
import SecuredRoute from "./utils/security/SecuredRoute";
import AdminRoute from "./utils/security/AdminRoute";

import AuthContext from "./context/auth_context";

import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import store from "./reducers";
import { securityActions } from "./reducers/security";
import { Helmet } from "react-helmet-async";

//pages site
import Home from "./pages/Site/Home";
import Services from "./pages/Site/Services";
import AboutUs from "./pages/Site/AboutUs";
import TehnologySolutions from "./pages/Site/TehnologySolutions";
import MissionVision from "./pages/Site/MissionVision";
import Expertise from "./pages/Site/Expertise";
import Contact from "./pages/Site/Contact";
import Siem from "./pages/Site/Siem";
import Soc from "./pages/Site/Soc";
import Blog from "./pages/Site/Blog";
import BlogDetail from "./pages/Site/BlogDetail";
import CyberSecurity from "./pages/Site/CyberSecurity";
import PenetrationTesting from "./pages/Site/PenetrationTesting";
import TacticalThreatIntelligence from "./pages/Site/TacticalThreatIntelligence";

//pages admin
import SeoPagesForm from "./pages/Admin/SeoPagesForm";
import SeoPagesList from "./pages/Admin/SeoPagesList";
import Dashboard from "./pages/Admin/Dashboard";
import Login from "./pages/Admin/Login";

//css
import "./assets/header.scss";
import "./assets/elements.scss";
import "./assets/responsive.scss";
import "./assets/carousel.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import ErrorPage from "./pages/Site/ErrorPage";
import Qualys from "./pages/Site/Qualys";
import IbmGuardium from "./pages/Site/IbmGuardium";
import Catis from "./pages/Site/Catis";
import SafeViewer from "./pages/Site/SafeViewer";
import Resources from "./pages/Site/Resources";
import Partners from "./pages/Site/Partners";
import Qa from "./pages/Admin/Qa";
import QaList from "./pages/Admin/QaList";
import Document from "./pages/Admin/Document";
import DocumentList from "./pages/Admin/DocumentList";
import Client from "./pages/Admin/Client";
import ClientList from "./pages/Admin/ClientList";
import ResourcesAdmin from "./pages/Admin/Resources";
import ResourcesList from "./pages/Admin/ResourcesList";
import Team from "./pages/Admin/Team";
import TeamList from "./pages/Admin/TeamList";
import BlogList from "./pages/Admin/BlogList";
import BlogAdmin from "./pages/Admin/Blog";
import BlogDetailAdmin from "./pages/Admin/BlogDetailAdmin";
import CaseStudy from "./pages/Admin/CaseStudy";
import CaseStudyList from "./pages/Admin/CaseStudyList";
import ContactFormList from "./pages/Admin/ContactFormList";
import NotFound from "./components/Admin/NotFound";
import RoleList from "./pages/Admin/RoleList";
import Role from "./pages/Admin/Role";
import User from "./pages/Admin/User";
import UserList from "./pages/Admin/UserList";
import { locationPath } from "./constants/appRoutes";
import Testimonials from "./pages/Admin/Testimonials";
import TestimonialsList from "./pages/Admin/TestimonialsList";
import QaSorting from "./pages/Admin/QaSorting";
import IbmGuardiumLanding from "./pages/Site/IbmGuardiumLanding";
import Ai from "./pages/Site/Ai";

console.log = function () {};
console.warn = function () {};
console.error = function () {};

const phpToken = localStorage.getItem("phpToken");
const loggedUser = JSON.parse(localStorage.getItem("loggedUser"));

if (phpToken) {
  let items = {
    validToken: phpToken,
    loggedUser,
  };
  store.dispatch(securityActions.setCurrentUser(items));
}

function App() {
  const loggedUserReducer = useSelector((state) => state.loggedUserReducer);

  const checkRoute = (component, action) => {
    const allowedActions = loggedUserReducer?.loggedUser?.loggedUser?.roles[0];
    if (allowedActions)
      if (!allowedActions.includes(action)) {
        return NotFound;
      } else {
        return component;
      }
  };

  return (
    <AuthContext.Provider
      value={{
        ...loggedUserReducer,
      }}
    >
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-78B1QF75X4"
        ></script>
        <script async>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-78B1QF75X4');
          `}
        </script>
        <script>
          {`
            (function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:3775182,hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
          `}
        </script>
      </Helmet>
      <Routes>
        <Route
          exact
          path="/"
          element={
            <SecuredRoute>
              <Home />
            </SecuredRoute>
          }
        />
        <Route
          path="/services"
          element={
            <SecuredRoute>
              <Services />
            </SecuredRoute>
          }
        />
        <Route
          path="/tehnology-solutions"
          element={
            <SecuredRoute>
              <TehnologySolutions />
            </SecuredRoute>
          }
        />
        <Route
          path="/siem"
          element={
            <SecuredRoute>
              <Siem />
            </SecuredRoute>
          }
        />
        <Route
          path="/penetraciono-testiranje"
          element={
            <SecuredRoute>
              <PenetrationTesting />
            </SecuredRoute>
          }
        />
        <Route
          path="/tactical-threat-intelligence"
          element={
            <SecuredRoute>
              <TacticalThreatIntelligence />
            </SecuredRoute>
          }
        />
        <Route
          path="/qualys"
          element={
            <SecuredRoute>
              <Qualys />
            </SecuredRoute>
          }
        />
        <Route
          path="/ibm-guardium"
          element={
            <SecuredRoute>
              <IbmGuardium />
            </SecuredRoute>
          }
        />
        <Route
          path="/ibm-guardium-landing"
          element={
            <SecuredRoute>
              <IbmGuardiumLanding />
            </SecuredRoute>
          }
        />
        <Route
          path="/irms"
          element={
            <SecuredRoute>
              <Catis />
            </SecuredRoute>
          }
        />
        <Route
          path="/safe-viewer"
          element={
            <SecuredRoute>
              <SafeViewer />
            </SecuredRoute>
          }
        />
        <Route
          path="/soc"
          element={
            <SecuredRoute>
              <Soc />
            </SecuredRoute>
          }
        />
        <Route
          path="/mission-and-vision"
          element={
            <SecuredRoute>
              <MissionVision />
            </SecuredRoute>
          }
        />
        <Route
          path="/contact"
          element={
            <SecuredRoute>
              <Contact />
            </SecuredRoute>
          }
        />
        <Route
          path="/expertise"
          element={
            <SecuredRoute>
              <Expertise />
            </SecuredRoute>
          }
        />
        <Route
          path="/about-us"
          element={
            <SecuredRoute>
              <AboutUs />
            </SecuredRoute>
          }
        />
        <Route
          path="*"
          element={
            <SecuredRoute>
              <ErrorPage />
            </SecuredRoute>
          }
        />
        <Route
          path="/blog"
          element={
            <SecuredRoute>
              <Blog />
            </SecuredRoute>
          }
        />
        <Route
          path="/resources"
          element={
            <SecuredRoute>
              <Resources />
            </SecuredRoute>
          }
        />
        <Route
          path="/ai"
          element={
            <SecuredRoute>
              <Ai />
            </SecuredRoute>
          }
        />
        <Route
          path="/blog-detaljno/:url/:id"
          element={
            <SecuredRoute>
              <BlogDetail />
            </SecuredRoute>
          }
        />
        <Route
          path="/cyber-security"
          element={
            <SecuredRoute>
              <CyberSecurity />
            </SecuredRoute>
          }
        />
        <Route
          path="/partners"
          element={
            <SecuredRoute>
              <Partners />
            </SecuredRoute>
          }
        />

        {/* ADMIN RUTE */}
        <Route exact path="/adminManager/login" element={<Login />}></Route>
        <Route
          exact
          path={locationPath.DASHBOARD}
          element={
            <AdminRoute component={checkRoute(Dashboard, "MENU_DASHBOARD")} />
          }
        ></Route>
        <Route
          exact
          path={locationPath.QA_ADD}
          element={<AdminRoute component={checkRoute(Qa, "MENU_QA")} />}
        ></Route>
        <Route
          exact
          path={locationPath.QA_EDIT}
          element={<AdminRoute component={checkRoute(Qa, "MENU_QA")} />}
        ></Route>
        <Route
          exact
          path={locationPath.QA_LIST}
          element={<AdminRoute component={checkRoute(QaList, "MENU_QA")} />}
        ></Route>
        <Route
          exact
          path={locationPath.QA_SORTING}
          element={<AdminRoute component={checkRoute(QaSorting, "MENU_QA")} />}
        ></Route>
        <Route
          exact
          path={locationPath.CLIENT_ADD}
          element={
            <AdminRoute component={checkRoute(Client, "MENU_CLIENTS")} />
          }
        ></Route>
        <Route
          exact
          path={locationPath.CLIENT_EDIT}
          element={
            <AdminRoute component={checkRoute(Client, "MENU_CLIENTS")} />
          }
        ></Route>
        <Route
          exact
          path={locationPath.CLIENT_LIST}
          element={
            <AdminRoute component={checkRoute(ClientList, "MENU_CLIENTS")} />
          }
        ></Route>
        <Route
          exact
          path={locationPath.TEAM_ADD}
          element={<AdminRoute component={checkRoute(Team, "MENU_TEAM")} />}
        ></Route>
        <Route
          exact
          path={locationPath.TEAM_EDIT}
          element={<AdminRoute component={checkRoute(Team, "MENU_TEAM")} />}
        ></Route>
        <Route
          exact
          path={locationPath.TEAM_LIST}
          element={<AdminRoute component={checkRoute(TeamList, "MENU_TEAM")} />}
        ></Route>
        <Route
          exact
          path={locationPath.TESTIMONIALS_ADD}
          element={
            <AdminRoute
              component={checkRoute(Testimonials, "MENU_TESTIMONIALS")}
            />
          }
        ></Route>
        <Route
          exact
          path={locationPath.TESTIMONIALS_EDIT}
          element={
            <AdminRoute
              component={checkRoute(Testimonials, "MENU_TESTIMONIALS")}
            />
          }
        ></Route>
        <Route
          exact
          path={locationPath.TESTIMONIALS_LIST}
          element={
            <AdminRoute
              component={checkRoute(TestimonialsList, "MENU_TESTIMONIALS")}
            />
          }
        ></Route>
        <Route
          exact
          path={locationPath.USER_ADD}
          element={<AdminRoute component={checkRoute(User, "MENU_USERS")} />}
        ></Route>
        <Route
          exact
          path={locationPath.USER_EDIT}
          element={<AdminRoute component={checkRoute(User, "MENU_USERS")} />}
        ></Route>
        <Route
          exact
          path={locationPath.USER_LIST}
          element={
            <AdminRoute component={checkRoute(UserList, "MENU_USERS")} />
          }
        ></Route>
        <Route
          exact
          path={locationPath.RESOURCES_ADD}
          element={
            <AdminRoute
              component={checkRoute(ResourcesAdmin, "MENU_RESOURCES")}
            />
          }
        ></Route>
        <Route
          exact
          path={locationPath.RESOURCES_EDIT}
          element={
            <AdminRoute
              component={checkRoute(ResourcesAdmin, "MENU_RESOURCES")}
            />
          }
        ></Route>
        <Route
          exact
          path={locationPath.RESOURCES_LIST}
          element={
            <AdminRoute
              component={checkRoute(ResourcesList, "MENU_RESOURCES")}
            />
          }
        ></Route>
        <Route
          exact
          path={locationPath.ROLE_ADD}
          element={<AdminRoute component={checkRoute(Role, "MENU_ROLES")} />}
        ></Route>
        <Route
          exact
          path={locationPath.ROLE_EDIT}
          element={<AdminRoute component={checkRoute(Role, "MENU_ROLES")} />}
        ></Route>
        <Route
          exact
          path={locationPath.ROLE_LIST}
          element={
            <AdminRoute component={checkRoute(RoleList, "MENU_ROLES")} />
          }
        ></Route>
        <Route
          exact
          path={locationPath.BLOG_ADD}
          element={
            <AdminRoute component={checkRoute(BlogAdmin, "MENU_BLOG")} />
          }
        ></Route>
        <Route
          exact
          path={locationPath.BLOG_EDIT}
          element={
            <AdminRoute component={checkRoute(BlogAdmin, "MENU_BLOG")} />
          }
        ></Route>
        <Route
          exact
          path={locationPath.BLOG_TRANSLATION_ADD}
          element={
            <AdminRoute component={checkRoute(BlogDetailAdmin, "MENU_BLOG")} />
          }
        ></Route>
        <Route
          exact
          path={locationPath.BLOG_TRANSLATION_EDIT}
          element={
            <AdminRoute component={checkRoute(BlogDetailAdmin, "MENU_BLOG")} />
          }
        ></Route>
        <Route
          exact
          path={locationPath.BLOG_LIST}
          element={<AdminRoute component={checkRoute(BlogList, "MENU_BLOG")} />}
        ></Route>
        <Route
          exact
          path={locationPath.DOCUMENT_ADD}
          element={
            <AdminRoute component={checkRoute(Document, "MENU_DOCUMENTS")} />
          }
        ></Route>
        <Route
          exact
          path={locationPath.DOCUMENT_EDIT}
          element={
            <AdminRoute component={checkRoute(Document, "MENU_DOCUMENTS")} />
          }
        ></Route>
        <Route
          exact
          path={locationPath.DOCUMENT_LIST}
          element={
            <AdminRoute
              component={checkRoute(DocumentList, "MENU_DOCUMENTS")}
            />
          }
        ></Route>
        <Route
          exact
          path={locationPath.CASE_STUDY_ADD}
          element={
            <AdminRoute component={checkRoute(CaseStudy, "MENU_CASE_STUDY")} />
          }
        ></Route>
        <Route
          exact
          path={locationPath.CASE_STUDY_EDIT}
          element={
            <AdminRoute component={checkRoute(CaseStudy, "MENU_CASE_STUDY")} />
          }
        ></Route>
        <Route
          exact
          path={locationPath.CASE_STUDY_LIST}
          element={
            <AdminRoute
              component={checkRoute(CaseStudyList, "MENU_CASE_STUDY")}
            />
          }
        ></Route>
        <Route
          exact
          path={locationPath.CONTACT_LIST}
          element={
            <AdminRoute
              component={checkRoute(ContactFormList, "MENU_CONTACT_FORM")}
            />
          }
        ></Route>
        <Route
          exact
          path={locationPath.SEO_LIST}
          element={
            <AdminRoute component={checkRoute(SeoPagesList, "MENU_SEO")} />
          }
        ></Route>
        <Route
          exact
          path={locationPath.SEO_FORM}
          element={
            <AdminRoute component={checkRoute(SeoPagesForm, "MENU_SEO")} />
          }
        ></Route>
      </Routes>
      <ToastContainer />
    </AuthContext.Provider>
  );
}

export default App;
