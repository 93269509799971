import React from "react";

const CatisService = ({ t }) => {
  return (
    <div className="service-our-service-item-holder-content">
      <div className="container-fluid">
        <div className="row">
          <div className="service-our-service-custom-template-holder">
            <div className="serive-our-custom-template-text-block">
              <h1>{t("catis.title3")}</h1>
              <p>{t("catis.desc3")}</p>
            </div>
            <div
              className="serive-our-custom-template-image-block"
              style={{ backgroundImage: "url(/images/catis-res.png)" }}
            >
              <ul>
                <li>{t("catis.li-1")}</li>
                <li>{t("catis.li-2")}</li>
                <li>{t("catis.li-3")}</li>
                <li>{t("catis.li-4")}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CatisService;
