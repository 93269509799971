import React from "react";

const RelatedServicesLanding = ({
  title,
  key1,
  key2,
  key3,
  value1,
  value2,
  value3,
}) => {
  return (
    <div className="related-services-holder-content related-service-holder-landing">
      <div className="related-services-content-center">
        <h3>{title}</h3>
        <div className="related-services-list-items">
          <div className="related-services-item">
            <div className="related-services-item-title">{key1}</div>
            <div className="related-services-item-desc">{value1}</div>
          </div>
          <div className="related-services-item">
            <div className="related-services-item-title">{key2}</div>
            <div className="related-services-item-desc">{value2}</div>
          </div>
          <div className="related-services-item">
            <div className="related-services-item-title">{key3}</div>
            <div className="related-services-item-desc">{value3}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RelatedServicesLanding;
