import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <div className="footer-content-holder des-block">
        <div className="container-fluid">
          <div className="row">
            <div className="footer-content-holder-main">
              <div className="left-side-footer">
                <div className="footer-logo-item">
                  <img
                    src="/images/footer-logo.png"
                    className="img-fluid"
                    alt="Oktacron"
                  />
                </div>
                <div className="footer-reserved-item">
                  @2023 All Rights Reserved.
                </div>
                <div className="footer-social-item">
                  <div className="footer-social-item-icon">
                    <a
                      href="https://www.facebook.com/profile.php?id=100085354207924"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="/images/face.png"
                        className="img-fluid"
                        alt="Oktacron"
                      />
                    </a>
                  </div>
                  <div className="footer-social-item-icon">
                    <a
                      href="https://www.instagram.com/oktacron/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="/images/insta.png"
                        className="img-fluid"
                        alt="Oktacron"
                      />
                    </a>
                  </div>
                  <div className="footer-social-item-icon">
                    <a
                      href="https://www.linkedin.com/company/oktacron/mycompany/?viewAsMember=true"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="/images/ln-logo.svg"
                        className="img-fluid"
                        alt="Oktacron"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="right-side-footer">
                <div className="footer-items-list-left">
                  <ul>
                    <li>
                      <Link to="/ai">AI</Link>
                    </li>
                    <li>
                      <Link to="/services">{t("menu.services")}</Link>
                    </li>
                    <li>
                      <a
                        href="https://oktacron.rs/cyber-security-aggregator.html"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Cyber news
                      </a>
                    </li>
                    <li>
                      <Link to="/soc">{t("menu.soc")}</Link>
                    </li>
                    <li>
                      <Link to="/about-us">{t("menu.about")}</Link>
                    </li>
                    <li>
                      <Link to="/blog">{t("menu.blog")}</Link>
                    </li>
                    <li>
                      <Link to="/resources">{t("menu.resources")}</Link>
                    </li>
                    <li>
                      <Link to="/contact">{t("menu.contact")}</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-content-holder mob-block">
        <div className="footer-mobile-block-1">
          <div className="footer-mobile-social-block">
            <div className="footer-mobile-social-block-item">
              <a
                href="https://www.facebook.com/profile.php?id=100085354207924"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="/images/face.png"
                  className="img-fluid"
                  alt="Oktacron"
                />
              </a>
            </div>
            <div className="footer-mobile-social-block-item">
              <a
                href="https://www.instagram.com/oktacron/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="/images/insta.png"
                  className="img-fluid"
                  alt="Oktacron"
                />
              </a>
            </div>
            <div className="footer-mobile-social-block-item">
              <a
                href="https://www.linkedin.com/company/oktacron/mycompany/?viewAsMember=true"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="/images/ln-logo.svg"
                  className="img-fluid"
                  alt="Oktacron"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="footer-mobile-block-2">
          <div className="footer-mobile-block-list-menu">
            <ul>
              <li>
                <Link to="/ai">AI</Link>
              </li>
              <li>
                <Link to="/services">{t("menu.services")}</Link>
              </li>
              <li>
                <a
                  href="https://oktacron.rs/cyber-security-aggregator.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Cyber news
                </a>
              </li>
              <li>
                <Link to="/soc">{t("menu.soc")}</Link>
              </li>
              <li>
                <Link to="/about-us">{t("menu.about")}</Link>
              </li>
              <li>
                <Link to="/blog">{t("menu.blog")}</Link>
              </li>
              <li>
                <Link to="/resources">{t("menu.resources")}</Link>
              </li>
              <li>
                <Link to="/contact">{t("menu.contact")}</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-mobile-block-3">
          <div className="footer-logo-item-mobile">
            <img
              src="/images/footer-logo.png"
              className="img-fluid"
              alt="Oktacron"
            />
          </div>
          <div className="footer-reserved-item-mobile">
            @2023 All Rights Reserved.
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Footer;
