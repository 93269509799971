import i18next from "i18next";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Navigation = () => {
  const [activeLanguage, setActiveLanguage] = useState(i18next.language);
  const [activeListItem, setActiveListItem] = useState(null);
  const [mobileMenu, setMobileMenu] = useState(false);

  const { t } = useTranslation();

  const changeLanguageFunction = (code) => {
    i18next.changeLanguage(code);
    setActiveLanguage(code);
  };

  const handleMobileMenuToggle = () => {
    setMobileMenu(!mobileMenu);
    document.body.classList.toggle("menu-open", !mobileMenu);
  };

  const setActiveListItemFunction = () => {};

  return (
    <div
      className="header-content-main"
      onMouseLeave={() => setActiveListItem(null)}
    >
      <div className="container-fluid">
        <div className="row">
          <div className="header-items-list-holder des-block">
            <div className="header-items-list-left">
              <ul>
                <li onMouseEnter={() => setActiveListItem(null)}>
                  <Link to="/ai">AI</Link>
                </li>
                <li onMouseEnter={() => setActiveListItem("services")}>
                  <Link to="/services">
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="28.153"
                        height="14.251"
                        viewBox="0 0 28.153 14.251"
                      >
                        <path
                          id="Path_3883"
                          data-name="Path 3883"
                          d="M26.81,0,13.452,12.159,0,0"
                          transform="translate(0.671 0.742)"
                          fill="none"
                          stroke="#281d42"
                          strokeWidth="2"
                        />
                      </svg>
                    </span>
                    {t("menu.services")}
                  </Link>

                  <div
                    className={`sub-header-menu ${
                      activeListItem === "services" && "active"
                    }`}
                  >
                    <div className="container-fluid">
                      <div className="row">
                        <div className="sub-header-menu-items">
                          <ul>
                            <li onClick={() => setActiveListItem(null)}>
                              <a href="/services#cyberGuardian">
                                {t("menuItems.services.guaridan")}
                              </a>
                            </li>
                            <li onClick={() => setActiveListItem(null)}>
                              <a href="/services#penetrationTesting">
                                {t("menuItems.services.penetration")}
                              </a>
                            </li>
                            <li onClick={() => setActiveListItem(null)}>
                              <a href="/services#riskAssessment">
                                {t("menuItems.services.risk")}
                              </a>
                            </li>
                            <li onClick={() => setActiveListItem(null)}>
                              <a href="/services#protectionOfDatabases">
                                {t("menuItems.services.db")}
                              </a>
                            </li>
                            <li onClick={() => setActiveListItem(null)}>
                              <a href="/services#vulnerabilityManagement">
                                {t("menuItems.services.vulnerability")}
                              </a>
                            </li>
                            <li onClick={() => setActiveListItem(null)}>
                              <a href="/services#soc">
                                {t("menuItems.services.soc")}
                              </a>
                            </li>
                            <li onClick={() => setActiveListItem(null)}>
                              <a href="/services#digitalTraps">
                                {t("menuItems.services.digital")}
                              </a>
                            </li>
                            <li onClick={() => setActiveListItem(null)}>
                              <a href="/services#tacticalThreatIntelligence">
                                {t("menuItems.services.tactical")}
                              </a>
                            </li>
                            <li onClick={() => setActiveListItem(null)}>
                              <a href="/services#incidentManagement">
                                {t("menuItems.services.management")}
                              </a>
                            </li>
                            <li onClick={() => setActiveListItem(null)}>
                              <a href="/services#incidentRecoverySupport">
                                {t("menuItems.services.incident")}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li onMouseEnter={() => setActiveListItem("tehnology")}>
                  <Link to="/tehnology-solutions">
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="28.153"
                        height="14.251"
                        viewBox="0 0 28.153 14.251"
                      >
                        <path
                          id="Path_3883"
                          data-name="Path 3883"
                          d="M26.81,0,13.452,12.159,0,0"
                          transform="translate(0.671 0.742)"
                          fill="none"
                          stroke="#281d42"
                          strokeWidth="2"
                        />
                      </svg>
                    </span>
                    {t("menu.tehnology")}
                  </Link>
                  <div
                    className={`sub-header-menu ${
                      activeListItem === "tehnology" && "active"
                    }`}
                  >
                    <div className="container-fluid">
                      <div className="row">
                        <div className="sub-header-menu-items">
                          <ul>
                            <li onClick={() => setActiveListItem(null)}>
                              <Link to="/ibm-guardium">IBM Guardium</Link>
                            </li>
                            <li onClick={() => setActiveListItem(null)}>
                              <Link to="/qualys">Qualys</Link>
                            </li>
                            <li onClick={() => setActiveListItem(null)}>
                              <Link to="/siem">SIEM</Link>
                            </li>
                            <li onClick={() => setActiveListItem(null)}>
                              <Link to="/irms">IRMS</Link>
                            </li>
                            <li onClick={() => setActiveListItem(null)}>
                              <Link to="/safe-viewer">Safe Viewer</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li onMouseEnter={() => setActiveListItem(null)}>
                  <Link to="/soc">{t("menu.soc")}</Link>
                </li>
              </ul>
            </div>
            <div className="header-items-list-center">
              <Link to="/">
                <img
                  src="/images/logo.png"
                  className="img-fluid logo-image-center-header"
                  alt="Oktacron"
                />
              </Link>
            </div>
            <div className="header-items-list-right">
              <ul>
                <li
                  onMouseEnter={() => setActiveListItem("about")}
                  // onClick={() => setActiveListItem(null)}
                >
                  <Link to="/about-us">
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="28.153"
                        height="14.251"
                        viewBox="0 0 28.153 14.251"
                      >
                        <path
                          id="Path_3883"
                          data-name="Path 3883"
                          d="M26.81,0,13.452,12.159,0,0"
                          transform="translate(0.671 0.742)"
                          fill="none"
                          stroke="#281d42"
                          strokeWidth="2"
                        />
                      </svg>
                    </span>
                    {t("menu.about")}
                  </Link>

                  <div
                    className={`sub-header-menu ${
                      activeListItem === "about" && "active"
                    }`}
                  >
                    <div className="container-fluid">
                      <div className="row">
                        <div className="sub-header-menu-items">
                          <ul>
                            <li onClick={() => setActiveListItem(null)}>
                              <Link to="/about-us">{t("menu.about")}</Link>
                            </li>
                            <li onClick={() => setActiveListItem(null)}>
                              <Link to="/mission-and-vision">
                                {t("menu.mission")}
                              </Link>
                            </li>
                            <li onClick={() => setActiveListItem(null)}>
                              <Link to="/expertise">
                                {t("menu.ekspertiza")}
                              </Link>
                            </li>
                            <li onClick={() => setActiveListItem(null)}>
                              <Link to="/partners">{t("menu.partner")}</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li onMouseEnter={() => setActiveListItem(null)}>
                  <Link to="/blog">{t("menu.blog")}</Link>
                </li>
                <li onMouseEnter={() => setActiveListItem(null)}>
                  <Link to="/resources">{t("menu.resources")}</Link>
                </li>
                <li onMouseEnter={() => setActiveListItem(null)}>
                  <Link to="/contact">{t("menu.contact")}</Link>
                </li>
              </ul>
              <div className="header-items-list-languages">
                <div
                  className={`language-item-header language-item-header-sr ${
                    activeLanguage === "sr" && "active-language"
                  }`}
                  onClick={() => changeLanguageFunction("sr")}
                >
                  SRB
                </div>
                <div
                  className={`language-item-header language-item-header-en ${
                    activeLanguage === "en" && "active-language"
                  }`}
                  onClick={() => changeLanguageFunction("en")}
                >
                  {" "}
                  ENG
                </div>
              </div>
            </div>
          </div>
          <div className="mob-block">
            <div class={`mobiel-header-modal ${mobileMenu && "active"}`}>
              <ul>
                <li onClick={handleMobileMenuToggle}>
                  <Link to="/ai">AI</Link>
                </li>

                <li onClick={handleMobileMenuToggle}>
                  <Link to="/services">{t("menu.services")}</Link>
                </li>

                <li onClick={handleMobileMenuToggle}>
                  <Link to="/tehnology-solutions">{t("menu.tehnology")}</Link>
                </li>

                <li onClick={handleMobileMenuToggle}>
                  <Link to="/soc">{t("menu.soc")}</Link>
                </li>
                <li onClick={handleMobileMenuToggle}>
                  <Link to="/about-us">{t("menu.about")}</Link>
                </li>
                <li onClick={handleMobileMenuToggle}>
                  <Link to="/mission-and-vision">Misija i vizija</Link>
                </li>
                <li onClick={handleMobileMenuToggle}>
                  <Link to="/expertise">Ekspertiza</Link>
                </li>
                <li onClick={handleMobileMenuToggle}>
                  <Link to="/partners">Partneri</Link>
                </li>

                <li onClick={handleMobileMenuToggle}>
                  <Link to="/blog">{t("menu.blog")}</Link>
                </li>

                <li onClick={handleMobileMenuToggle}>
                  <Link to="/resources">{t("menu.resources")}</Link>
                </li>

                <li onClick={handleMobileMenuToggle}>
                  <Link to="/contact">{t("menu.contact")}</Link>
                </li>
              </ul>
            </div>
            <div className="header-menu-mobile">
              <div className="header-menu-mobile-icon">
                <Link to="/">
                  <img
                    src="/images/octa-logo-mob.svg"
                    alt="Oktacron"
                    className="img-fluid"
                  />
                </Link>
              </div>

              <div className="header-items-list-languages header-lanaguage-mobile">
                <div
                  className={`language-item-header language-item-header-sr ${
                    activeLanguage === "sr" && "active-language"
                  }`}
                  onClick={() => changeLanguageFunction("sr")}
                >
                  SRB
                </div>
                <div
                  className={`language-item-header language-item-header-en ${
                    activeLanguage === "en" && "active-language"
                  }`}
                  onClick={() => changeLanguageFunction("en")}
                >
                  {" "}
                  ENG
                </div>
              </div>
              <div
                className="header-menu-mobile-menu"
                // onClick={onClick={handleMobileMenuToggle}}
                onClick={handleMobileMenuToggle}
              >
                <div id="nav-icon4" className={`${mobileMenu && "open"}`}>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navigation;
