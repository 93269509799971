import React, { useEffect } from "react";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchClientsListItems } from "../../../../actions/Site/global";
import { baseUrl } from "../../../../utils";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <img
      src="/images/bright.svg"
      alt="Oktacron"
      className="icon-slider-right"
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <img
      src="/images/bleft.svg"
      alt="Oktacron"
      className="icon-slider-left"
      onClick={onClick}
    />
  );
}

const Clients = () => {
  const dispatch = useDispatch();

  const { dataPagesListClients } = useSelector((state) => state.globalReducer);

  useEffect(() => {
    dispatch(fetchClientsListItems());
  }, [dispatch]);

  var settings = {
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  return (
    <div className="clients-section-holder">
      <div className="container-fluid">
        <div className="row">
          <div className="clients-section-holder-content">
            <div className="clients-section-content">
              <h2>Naši klijenti</h2>

              <div className="carouse-client-content">
                <Slider {...settings}>
                  {dataPagesListClients?.map((item, key) => (
                    <a
                      href={item.acLink}
                      target="_blank"
                      key={key}
                      rel="noreferrer"
                    >
                      <div className="client-item">
                        <img
                          src={baseUrl + "images/" + item.acImage}
                          className="img-fluid"
                          alt={item.acName}
                        />
                      </div>
                    </a>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Clients;
