import React from "react";
import ButtonIcon from "./ButtonIcon";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

const Resources = () => {
  const { t } = useTranslation();
  return (
    <div className="resource-services-holder-content">
      <div className="resource-services-content-center">
        <h3>{t("menu.resources")}</h3>
        <div className="resource-services-list-items">
          <div className="resource-services-item">
            <div className="resource-services-item-title">
              Oktacron IBM Guardium
            </div>
            <ButtonIcon
              documentName={
                i18next.language === "sr"
                  ? `/documents/Oktacron IBM Guardium.pdf`
                  : `/documents/Oktacron IBM Guardium.pdf`
              }
              nameClass={`custom-resources-button`}
              text={`Download`}
              img={`icon-down.svg`}
            />
          </div>
          <div className="resource-services-item">
            <div className="resource-services-item-title">
              Oktacron portfolio
            </div>
            <ButtonIcon
              documentName={
                i18next.language === "sr"
                  ? `/documents/Oktacron portfolio.pdf`
                  : `/documents/Oktacron portfolio.pdf`
              }
              nameClass={`custom-resources-button`}
              text={`Download`}
              img={`icon-down.svg`}
            />
          </div>
          <div className="resource-services-item">
            <div className="resource-services-item-title">
              Oktacron Managed Services
            </div>
            <ButtonIcon
              documentName={
                i18next.language === "sr"
                  ? `/documents/Oktacron Managed Services.pdf`
                  : `/documents/Oktacron Managed Services.pdf`
              }
              nameClass={`custom-resources-button`}
              text={`Download`}
              img={`icon-down.svg`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resources;
