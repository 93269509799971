import React from "react";
import Button from "../../../components/Site/Reusable/Button";
import { Link } from "react-router-dom";

const RelatedServices = ({
  title,
  buttonTitle,
  text1,
  text2,
  text3,
  link1,
  link2,
  link3,
}) => {
  return (
    <div className="related-services-holder-content">
      <div className="related-services-content-center">
        <h3>{title}</h3>
        <div className="related-services-list-items">
          <div className="related-services-item">
            <Link to={link1}>
              <div className="related-services-item-title">{text1}</div>
            </Link>
          </div>
          <div className="related-services-item">
            <Link to={link2}>
              <div className="related-services-item-title">{text2}</div>
            </Link>
          </div>
          <div className="related-services-item">
            <Link to={link3}>
              <div className="related-services-item-title">{text3}</div>
            </Link>
          </div>
        </div>
        <Button
          link={`services`}
          nameClass={`custom-info-button`}
          text={buttonTitle}
        />
      </div>
    </div>
  );
};

export default RelatedServices;
