import React from "react";

const InfoBlockAi = ({ text }) => {
  return (
    <div className="info-holder-container-ai">
      <div className="container-fluid">
        <div className="row">
          <div
            className="info-holder-ai"
            dangerouslySetInnerHTML={{ __html: text }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default InfoBlockAi;
