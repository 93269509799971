import React, { Fragment, useRef } from "react";
import HelmetSeo from "../../components/Site/Reusable/HelmetSeo";
import Hero from "../../components/Site/Reusable/Hero";
import SpacingItem from "../../components/Site/Reusable/SpacingItem";
import CyberInfo from "../../components/Site/Reusable/CyberInfo";
import TitleDescription from "../../components/Site/Reusable/TitleDescription";
import ImageBlock from "../../components/Site/Reusable/ImageBlock";
import TextButtonImageBckg from "../../components/Site/Reusable/TextButtonImageBckg";
import ImagesBlock from "../../components/Site/Sections/SOC/ImagesBlock";
import FourBlocks from "../../components/Site/Sections/SOC/FourBlocks";
import BlockImageElements from "../../components/Site/Reusable/BlockImageElements";

import "../../assets/soc.scss";
import RelatedServices from "../../components/Site/Reusable/RelatedServices";
import ExapandList from "../../components/Site/Sections/SOC/ExapandList";
import { fetchQaListItems, fetchSeoListItems } from "../../actions/Site/global";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import BlockInfoDescription from "../../components/Site/Reusable/BlockInfoDescription";
import BlockSpecialItemNumberLanding from "../../components/Site/Reusable/BlockSpecialItemNumberLanding";
import RelatedServicesLanding from "../../components/Site/Reusable/RelatedServicesLanding";
import TitleDescriptionLanding from "../../components/Site/Reusable/TitleDescriptionLanding";
import RelatedServicesImageLanding from "../../components/Site/Reusable/RelatedServicesImageLanding";
import BlockItemTitleWithImageBlock from "../../components/Site/Reusable/BlockItemTitleWithImageBlock";
import ContactFormLanding from "../../components/Site/Reusable/ContactFormLanding";
import { useLocation } from "react-router-dom";

const PenetrationTesting = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { hash } = useLocation();
  const sectionRef = useRef(null);
  const { dataPagesListQa, dataPagesListSeo } = useSelector(
    (state) => state.globalReducer
  );
  const [currentLanguage, setCurrentLanguage] = useState(i18next.language);
  const [startLang, setStartLang] = useState(0);

  useEffect(() => {
    // Ako postoji hash u URL-u
    if (hash) {
      // Pokušaj pronalaska elementa s ID-om koji odgovara hash-u
      const section = document.getElementById("forma");

      // Ako je pronađena sekcija, pomičemo se na nju
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [hash]);

  useEffect(() => {
    dispatch(fetchQaListItems(3));
    dispatch(fetchSeoListItems(18));

    const languageChangeHandler = (newLanguage) => {
      setCurrentLanguage(newLanguage);
    };

    // Pretplatite se na događaj promene jezika
    i18next.on("languageChanged", languageChangeHandler);

    setStartLang(1);

    // Očistite pretplatu kada se komponenta odmontira
    return () => {
      i18next.off("languageChanged", languageChangeHandler);
    };
  }, [dispatch]);

  useEffect(() => {
    if (startLang > 0) dispatch(fetchQaListItems(3));
  }, [currentLanguage]);

  return (
    <div className="penetration-testing-holder-main">
      <HelmetSeo
        title={dataPagesListSeo.length > 0 && dataPagesListSeo[0].acTitle}
        description={
          dataPagesListSeo.length > 0 && dataPagesListSeo[0].acDescription
        }
        keywords={dataPagesListSeo.length > 0 && dataPagesListSeo[0].acKeywords}
      />
      <Hero
        title={t("penetrationTestingLandingPage.hero.title")}
        img={`pen-test-landing-hero.png`}
      />
      <SpacingItem />
      <ContactFormLanding
        t={t}
        title={t("penetrationTestingLandingPage.contactForm.title")}
        phone={t("penetrationTestingLandingPage.contactForm.phone")}
        desc={t("penetrationTestingLandingPage.contactForm.desc")}
        type={`Penetraciono testiranje landing strana`}
      />
      <BlockInfoDescription
        text={t("penetrationTestingLandingPage.hero.desc")}
      />
      <BlockSpecialItemNumberLanding
        title={t("penetrationTestingLandingPage.blockNumber.title")}
        subtitle={t("penetrationTestingLandingPage.blockNumber.subtitle")}
        number={10}
      />
      <RelatedServicesLanding
        t={t}
        title={t("penetrationTestingLandingPage.threeItemsPercetange.title")}
        key1={t("penetrationTestingLandingPage.threeItemsPercetange.key1")}
        key2={t("penetrationTestingLandingPage.threeItemsPercetange.key2")}
        key3={t("penetrationTestingLandingPage.threeItemsPercetange.key3")}
        value1={t("penetrationTestingLandingPage.threeItemsPercetange.value1")}
        value2={t("penetrationTestingLandingPage.threeItemsPercetange.value2")}
        value3={t("penetrationTestingLandingPage.threeItemsPercetange.value3")}
      />
      <BlockItemTitleWithImageBlock
        image={`images/image-block-11.png`}
        title={t("penetrationTestingLandingPage.titleDescBlock.title")}
        desc={t("penetrationTestingLandingPage.titleDescBlock.desc")}
        title2={t("penetrationTestingLandingPage.imageTextTitleBlock.title2")}
        title3={t("penetrationTestingLandingPage.imageTextTitleBlock.title3")}
        list1={t("penetrationTestingLandingPage.imageTextTitleBlock.list1")}
        list2={t("penetrationTestingLandingPage.imageTextTitleBlock.list2")}
        list3={t("penetrationTestingLandingPage.imageTextTitleBlock.list3")}
        list4={t("penetrationTestingLandingPage.imageTextTitleBlock.list4")}
        list5={t("penetrationTestingLandingPage.imageTextTitleBlock.list5")}
        btn={t("penetrationTestingLandingPage.imageTextTitleBlock.btn")}
        link={`/documents/Penetration testing Oktacron OP.pdf`}
      />
      <RelatedServicesImageLanding
        image={`bckg-24.png`}
        title={t("penetrationTestingLandingPage.threeItemsPercetange2.title")}
        desc={t("penetrationTestingLandingPage.threeItemsPercetange2.desc")}
        key1={t("penetrationTestingLandingPage.threeItemsPercetange2.key1")}
        key2={t("penetrationTestingLandingPage.threeItemsPercetange2.key2")}
        key3={t("penetrationTestingLandingPage.threeItemsPercetange2.key3")}
        value1={t("penetrationTestingLandingPage.threeItemsPercetange2.value1")}
        value2={t("penetrationTestingLandingPage.threeItemsPercetange2.value2")}
        value3={t("penetrationTestingLandingPage.threeItemsPercetange2.value3")}
      />
      <TitleDescriptionLanding
        title={t("penetrationTestingLandingPage.faq-title")}
      />
      <ExapandList dataList={dataPagesListQa} />
    </div>
  );
};

export default PenetrationTesting;
