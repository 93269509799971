import React from "react";
import Button from "../../components/Site/Reusable/Button";
import { baseUrl } from "../../utils";

const BlogItem = (props) => {
  const { item } = props || {};
  const image = baseUrl + "images/" + item.acImage;
  const imageStyle = {
    backgroundImage: `url(${image})`,
  };

  return (
    <div
      className="blog-block-left-holder blog-block-holder"
      style={imageStyle}
    >
      <h2>{item.acTitle}</h2>
      <p>{item.acSubtitle}</p>
      <Button
        text="Detaljnije"
        nameClass={`custom-buttom-gold`}
        link={`blog-detaljno/${item.acUrl}/${item.anId}`}
      />
    </div>
  );
};

export default BlogItem;
