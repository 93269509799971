export const locationPath = {
  BLOG_ADD: "/adminManager/blog-add",
  BLOG_EDIT: "/adminManager/blog-edit/:id",
  BLOG_TRANSLATION_ADD: "/adminManager/add-blog-detail/:id",
  BLOG_TRANSLATION_EDIT: "/adminManager/edit-blog-detail/:id/:anId",
  BLOG_LIST: "/adminManager/blog-list",
  ROLE_ADD: "/adminManager/role-add",
  ROLE_EDIT: "/adminManager/role-edit/:id",
  ROLE_LIST: "/adminManager/role-list",
  USER_ADD: "/adminManager/user-add",
  USER_EDIT: "/adminManager/user-edit/:id",
  USER_LIST: "/adminManager/user-list",
  CLIENT_ADD: "/adminManager/client-add",
  CLIENT_EDIT: "/adminManager/client-edit/:id",
  CLIENT_LIST: "/adminManager/client-list",
  TEAM_ADD: "/adminManager/team-add",
  TEAM_EDIT: "/adminManager/team-edit/:id",
  TEAM_LIST: "/adminManager/team-list",
  TESTIMONIALS_ADD: "/adminManager/testimonials-add",
  TESTIMONIALS_EDIT: "/adminManager/testimonials-edit/:id",
  TESTIMONIALS_LIST: "/adminManager/testimonials-list",
  QA_ADD: "/adminManager/qa-add",
  QA_EDIT: "/adminManager/qa-edit/:id",
  QA_LIST: "/adminManager/qa-list",
  QA_SORTING: "/adminManager/qa-sorting",
  DOCUMENT_ADD: "/adminManager/document-add",
  DOCUMENT_EDIT: "/adminManager/document-edit/:id",
  DOCUMENT_LIST: "/adminManager/document-list",
  DASHBOARD: "/adminManager/dashboard",
  SEO_LIST: "/adminManager/seo/pages/list",
  SEO_FORM: "/adminManager/seo/pages/form/:id",
  CONTACT_LIST: "/adminManager/contact-form-list",
  CASE_STUDY_ADD: "/adminManager/case-study-add",
  CASE_STUDY_EDIT: "/adminManager/case-study-edit/:id",
  CASE_STUDY_LIST: "/adminManager/case-study-list",
  RESOURCES_ADD: "/adminManager/resources-add",
  RESOURCES_EDIT: "/adminManager/resources-edit/:id",
  RESOURCES_LIST: "/adminManager/resources-list",
};
