import React from "react";

const InfoWhiteBlock = ({ t }) => {
  return (
    <div className="service-info-holder-content">
      <div className="container-fluid">
        <div className="row">
          <div
            className="service-info-holder"
            dangerouslySetInnerHTML={{ __html: t("services.section3.text") }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default InfoWhiteBlock;
