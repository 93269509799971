import React, { Fragment } from "react";
import HelmetSeo from "../../components/Site/Reusable/HelmetSeo";
import { useTranslation } from "react-i18next";
import HeroAi from "../../components/Site/Reusable/HeroAi";
import SpacingItem from "../../components/Site/Reusable/SpacingItem";
import CyberInfo from "../../components/Site/Reusable/CyberInfo";
import InfoBlockAi from "../../components/Site/Reusable/InfoBlockAi";
import i18next from "i18next";

const Ai = () => {
  const { t } = useTranslation();
  const language = i18next.language;
  return (
    <Fragment>
      <HelmetSeo
        title="Budućnost sajber bezbednosti! | Oktacron"
        description="U svetu u kojem tehnologije brzo napreduju, sajber napadi postaju sve sofisticiraniji, posebno razvojem veštačke inteligencije (AI) koja napadačima omogućava da koriste inteligentne metode za ostvarivanje svojih ciljeva. Oktacron neprekidno prati tehnološke inovacije u sajber bezbednosti i proširuje svoj portfolio rešenjima baziranim na veštačkoj inteligenciji (AI) kako bi korisnicima obezbedio najsavremeniju zaštitu od naprednih sajber pretnji."
        keywords="ai, oktacron, cyber security, oktacron cyber security, security, cyber"
      />
      <div className="desktop-block-item">
        <HeroAi
          title={t("ai.title")}
          subtitle={t("ai.subtitle")}
          text={t("ai.text")}
          img={`ai-1.png`}
        />
        <SpacingItem />
      </div>
      <div className="mobile-block-item">
        <section className="hero-section-mobile-ai">
          <div className="hero-section-mobile-ai-content">
            <div className="hero-section-ai-image">
              <img
                src="/images/mobile-hero-ai.png"
                alt="ai"
                className="img-fluid"
              />
            </div>
            <div className="hero-section-ai-content-mobile">
              <div className="hero-bottom-line-spacing">
                <div className="container-fluid">
                  <div className="row">
                    <h2>{t("ai.subtitle")}</h2>
                    <h1
                      dangerouslySetInnerHTML={{
                        __html: t("ai.title"),
                      }}
                    ></h1>

                    <div className="hero-section-ai-logo-text">
                      <img
                        src="/images/logo-ai.png"
                        className="img-fluid"
                        alt="Oktacron"
                      />
                      <div className="hero-section-ai-logo-line"></div>
                      <h3>{t("ai.text")}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <CyberInfo text={t("ai.section2")} />
      <InfoBlockAi text={t("ai.section3")} />
      <section className="ai-section-4">
        <div className="ai-section-4-content">
          <h1>{t("ai.section4_title")}</h1>
          <h3
            dangerouslySetInnerHTML={{ __html: t("ai.section4_subtitle") }}
          ></h3>
          <div className="ai-section-items-list">
            <div className="ai-section-item">
              <h4>{t("ai.section4_text1")}</h4>
            </div>
            <div className="ai-section-item">
              <h4>{t("ai.section4_text2")}</h4>
            </div>
            <div className="ai-section-item">
              <h4>{t("ai.section4_text3")}</h4>
            </div>
            <div className="ai-section-item">
              <h4>{t("ai.section4_text4")}</h4>
            </div>
          </div>
        </div>
      </section>
      <section className="ai-section-5">
        <div className="ai-section-5-content">
          <div className="container-fluid">
            <div className="row">
              <h1
                dangerouslySetInnerHTML={{ __html: t("ai.section5_title") }}
              ></h1>
              <h3
                dangerouslySetInnerHTML={{ __html: t("ai.section5_subtitle") }}
              ></h3>
            </div>
          </div>
        </div>
      </section>
      <section className="ai-section-6">
        <div className="ai-section-6-content">
          {language == "sr" ? (
            <Fragment>
              <div className="mobile-block-item">
                <img
                  src="/images/robot-sr-mob.png"
                  alt="ai-6"
                  className="img-fluid"
                />
              </div>
              <div className="desktop-block-item">
                <img
                  src="/images/section6.png"
                  alt="ai-6"
                  className="img-fluid"
                />
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <div className="mobile-block-item">
                <img
                  src="/images/robot-en-mob.png"
                  alt="ai-6"
                  className="img-fluid"
                />
              </div>
              <div className="desktop-block-item">
                <img
                  src="/images/section6-en.png"
                  alt="ai-6"
                  className="img-fluid"
                />
              </div>
            </Fragment>
          )}
        </div>
      </section>
      <section className="ai-section-7">
        <div className="ai-section-7-content">
          <div className="container-fluid">
            <div className="row">
              <div className="ai-section-7-items-list">
                <div className="ai-section-7-item">
                  <h3>{t("ai.section_7_title_1")}</h3>
                  <h4>{t("ai.section_7_text_1")}</h4>
                </div>
                <div className="ai-section-7-item">
                  <h3>{t("ai.section_7_title_2")}</h3>
                  <h4>{t("ai.section_7_text_2")}</h4>
                </div>
                <div className="ai-section-7-item">
                  <h3>{t("ai.section_7_title_3")}</h3>
                  <h4>{t("ai.section_7_text_3")}</h4>
                </div>
                <div className="ai-section-7-item">
                  <h3>{t("ai.section_7_title_4")}</h3>
                  <h4>{t("ai.section_7_text_4")}</h4>
                </div>
                <div className="ai-section-7-item">
                  <h3>{t("ai.section_7_title_5")}</h3>
                  <h4>{t("ai.section_7_text_5")}</h4>
                </div>
                <div className="ai-section-7-item">
                  <h3>{t("ai.section_7_title_6")}</h3>
                  <h4>{t("ai.section_7_text_6")}</h4>
                </div>
                <div className="ai-section-7-item">
                  <h3>{t("ai.section_7_title_7")}</h3>
                  <h4>{t("ai.section_7_text_7")}</h4>
                </div>
                <div className="ai-section-7-item">
                  <h3>{t("ai.section_7_title_8")}</h3>
                  <h4>{t("ai.section_7_text_8")}</h4>
                </div>
                <div className="ai-section-7-item">
                  <h3>{t("ai.section_7_title_9")}</h3>
                  <h4>{t("ai.section_7_text_9")}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="ai-section-8 desktop-block-item">
        <div className="ai-section-8-content">
          <h2
            dangerouslySetInnerHTML={{ __html: t("ai.section_8_title") }}
          ></h2>
        </div>
      </section>
      <section className="ai-section-88 mobile-block-item">
        <div className="ai-section-8-content">
          <h2
            dangerouslySetInnerHTML={{ __html: t("ai.section_8_title") }}
          ></h2>
          <img src="/images/ai-8-mob.png" alt="ai-8" className="img-fluid" />
        </div>
      </section>
      <section className="ai-section-9">
        <div className="ai-section-9-content">
          <div className="container-fluid">
            <div className="row">
              <h2
                dangerouslySetInnerHTML={{ __html: t("ai.section_9_title") }}
              ></h2>
              <div className="ai-section-9-items-list">
                <div className="ai-section-9-item">
                  <h3>{t("ai.section_9_title_1")}</h3>
                  <h4>{t("ai.section_9_text_1")}</h4>
                </div>
                <div className="ai-section-9-item">
                  <h3>{t("ai.section_9_title_2")}</h3>
                  <h4>{t("ai.section_9_text_2")}</h4>
                </div>
                <div className="ai-section-9-item">
                  <h3>{t("ai.section_9_title_3")}</h3>
                  <h4>{t("ai.section_9_text_3")}</h4>
                </div>
                <div className="ai-section-9-item">
                  <h3>{t("ai.section_9_title_4")}</h3>
                  <h4>{t("ai.section_9_text_4")}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="ai-section-10 desktop-block-item">
        <div className="ai-section-10-content">
          <div className="container-fluid">
            <div className="row">
              <h2>{t("ai.section_10_title")}</h2>
              <h3>{t("ai.section_10_text")}</h3>
              <div className="ai-section-10-list-items">
                <div className="ai-section-10-item">
                  <h4 className="text-item-ai-10">
                    {t("ai.section_10_text_1")}
                  </h4>
                  <h4>{t("ai.section_10_text_2")}</h4>
                </div>
                <div className="ai-section-10-item">
                  <h4 className="text-item-ai-10">
                    {t("ai.section_10_text_3")}
                  </h4>
                  <h4>{t("ai.section_10_text_4")}</h4>
                </div>
                <div className="ai-section-10-item">
                  <h4 className="text-item-ai-10">
                    {t("ai.section_10_text_5")}
                  </h4>
                  <h4>{t("ai.section_10_text_6")}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="ai-section-11 mobile-block-item">
        <div className="ai-section-11-content">
          <div className="ai-section-11-image">
            <img
              src="/images/ai-10-mob.png"
              alt="Oktacron"
              className="img-fluid"
            />
            <h2>{t("ai.section_10_title")}</h2>
          </div>
          <div className="container-fluid">
            <div className="row">
              <h3>{t("ai.section_10_text")}</h3>
              <div className="ai-section-11-list-items">
                <div className="ai-section-11-item">
                  <h4 className="text-item-ai-11">
                    {t("ai.section_10_text_1")}
                  </h4>
                  <h4>{t("ai.section_10_text_2")}</h4>
                </div>
                <div className="ai-section-11-item">
                  <h4 className="text-item-ai-11">
                    {t("ai.section_10_text_3")}
                  </h4>
                  <h4>{t("ai.section_10_text_4")}</h4>
                </div>
                <div className="ai-section-11-item">
                  <h4 className="text-item-ai-11">
                    {t("ai.section_10_text_5")}
                  </h4>
                  <h4>{t("ai.section_10_text_6")}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Ai;
