import React from "react";

const SafeViewrService = ({ t }) => {
  return (
    <div className="service-our-service-item-holder-content">
      <div className="container-fluid">
        <div className="row">
          <div className="service-our-service-custom-template-holder">
            <div className="serive-our-custom-template-text-block">
              <h1>{t("safe.title2")}</h1>
              <p>{t("safe.desc2")}</p>
            </div>
            <div
              className="serive-our-custom-template-image-block"
              style={{ backgroundImage: "url(/images/safe-viewer-res.png)" }}
            >
              <ul>
                <li>{t("safe.li-1")}</li>
                <li>{t("safe.li-2")}</li>
                <li>{t("safe.li-3")}</li>
                <li>{t("safe.li-4")}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SafeViewrService;
