import React from "react";
import ShieldItem from "./ShieldItem";
import { Fragment } from "react";
import Button from "../../Reusable/Button";
import SpacingItem from "../../Reusable/SpacingItem";

const Shields = ({ t, link }) => {
  return (
    <Fragment>
      <div className="shields-section-holder">
        <div className="container-fluid">
          <div className="row">
            <div className="shields-section-holder-content">
              <div className="shields-section-content">
                <h2>{t("home.endToEnd.title")}</h2>
                <h3>{t("home.endToEnd.description")}</h3>
              </div>
              <div className="shields-section-content-list-items">
                <ShieldItem
                  image={`shield1.png`}
                  description={t("home.endToEnd.shield1_desc")}
                  title={t("home.endToEnd.shield1_title")}
                />
                <ShieldItem
                  image={`shield2.png`}
                  description={t("home.endToEnd.shield2_desc")}
                  title={t("home.endToEnd.shield2_title")}
                />
                <ShieldItem
                  image={`shield3.png`}
                  description={t("home.endToEnd.shield3_desc")}
                  title={t("home.endToEnd.shield3_title")}
                />
                <ShieldItem
                  image={`shield4.png`}
                  description={t("home.endToEnd.shield4_desc")}
                  title={t("home.endToEnd.shield4_title")}
                />
                <ShieldItem
                  image={`shield5.png`}
                  description={t("home.endToEnd.shield5_desc")}
                  title={t("home.endToEnd.shield5_title")}
                />
              </div>
              <div className="shields-section-content shields-section-content-down">
                <p>{t("home.endToEnd.subtitle")}</p>
                <Button
                  nameClass={`custom-button-gold`}
                  link={link}
                  text={t("home.endToEnd.button")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <SpacingItem />
    </Fragment>
  );
};

export default Shields;
