import React from "react";
import Button from "../../Reusable/Button";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import i18next from "i18next";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { fetchCaseStudyListItems } from "../../../../actions/Site/global";
import { baseUrl } from "../../../../utils";
import { useTranslation } from "react-i18next";

const InfoLine = ({ settings }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { dataPagesListCaseStudy } = useSelector(
    (state) => state.globalReducer
  );

  const [currentLanguage, setCurrentLanguage] = useState(i18next.language);
  const [startLang, setStartLang] = useState(0);

  useEffect(() => {
    dispatch(fetchCaseStudyListItems());

    const languageChangeHandler = (newLanguage) => {
      setCurrentLanguage(newLanguage);
    };

    // Pretplatite se na događaj promene jezika
    i18next.on("languageChanged", languageChangeHandler);

    setStartLang(1);

    // Očistite pretplatu kada se komponenta odmontira
    return () => {
      i18next.off("languageChanged", languageChangeHandler);
    };
  }, [dispatch]);

  useEffect(() => {
    if (startLang > 0) dispatch(fetchCaseStudyListItems());
  }, [currentLanguage]);

  return (
    <div className="info-section-holder">
      <div className="container-fluid">
        <div className="row">
          <Slider {...settings}>
            {dataPagesListCaseStudy?.map((item, key) => (
              <div className="info-section-holder-content" key={key}>
                <div className="info-section-content">
                  <h2>{item.acTitle}</h2>
                  <p>{item.acDescription}</p>
                  <Button
                    href={true}
                    link={baseUrl + "uploads/caseStudy/" + item.acDocument}
                    nameClass={`custom-button-gold2`}
                    text={t("home.btnReadMore")}
                  />
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default InfoLine;
