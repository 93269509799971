import React from "react";
import { Fragment } from "react";
import Breadcrumb from "../../components/Admin/Reusable/Breadcrumb";
import AggridTable from "../../components/Admin/Reusable/AggridTable";
import { useDispatch, useSelector } from "react-redux";
import { fetchBlogPagesList, fetchDeleteBlog } from "../../actions/Admin/blog";
import ButtonColumn from "../../components/Admin/Reusable/ButtonColumn";
import { useEffect } from "react";
import ButtonColumnLanguage from "../../components/Admin/Reusable/ButtonColumnLanguage";
import { primaryRender } from "../../utils/cellRenderTable";

const BlogList = () => {
  const dispatch = useDispatch();

  const { dataPagesList } = useSelector((state) => state.blogReducer);

  useEffect(() => {
    dispatch(fetchBlogPagesList());
  }, [dispatch]);

  //akcija brisanja iz liste
  const handleSubmitModal = (pageId) => {
    dispatch(fetchDeleteBlog(pageId));
  };

  const customCellRenderer = (params) => (
    <ButtonColumn
      editLink={`/adminManager/blog-edit/` + params.data.anId}
      deleteItemId={params.data.anId}
      deleteItemTitle={params.data.acTitle}
      value={params}
      submitActionModal={handleSubmitModal}
      addMoreButtonNews
    />
  );

  const customCellRendererLanguage = (params) => {
    return (
      <ButtonColumnLanguage
        value={params.data.children}
        id={params.data.anId}
      />
    );
  };

  //settings za tabelu
  const columnDefs = [
    { headerName: "ID", field: "anId", filter: true, width: 100 },
    {
      headerName: "Naslov",
      field: "acTitle",
      filter: "agTextColumnFilter",
      flex: 1,
    },
    {
      headerName: "Jezik",
      field: "isPrimary",
      cellRenderer: customCellRendererLanguage,
    },
    {
      headerName: "Istaknuta vest",
      field: "isPrimary",
      filter: "agTextColumnFilter",
      cellRenderer: primaryRender,
    },
    {
      headerName: "Kreirao",
      field: "ins_acUsername",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Izmenio",
      field: "edit_acUsername",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Datum kreiranja",
      field: "adTimeIns",
      filter: "agDateColumnFilter",
    },
    {
      headerName: "Datum izmene",
      field: "adTimeEdit",
      filter: "agDateColumnFilter",
    },
    {
      headerName: "Panel",
      field: "custom",
      cellRenderer: customCellRenderer,
      pinned: "right",
      width: 110,
    },
  ];

  return (
    <Fragment>
      <Breadcrumb title="Pregled blogova" type="blog-lista" />
      <AggridTable rowData={dataPagesList} columnDefs={columnDefs} />
    </Fragment>
  );
};

export default BlogList;
