import React from "react";

const ExpandListItem = ({
  title,
  description,
  handleOpenFunction,
  isActive,
}) => {
  const hendleClickOpen = (e) => {
    handleOpenFunction(e);
  };

  return (
    <div className={`expand-list-item ${isActive ? "active-expand-item" : ""}`}>
      <div
        className="expand-list-item-header"
        onClick={(e) => hendleClickOpen(e)}
      >
        <h4>{title}</h4>
        <img src="/images/soc-right.svg" className="img-fluid" alt="Oktacron" />
      </div>
      <div className={`expand-list-description ${isActive ? "active" : ""}`}>
        <p dangerouslySetInnerHTML={{ __html: description }} />
      </div>
    </div>
  );
};

export default ExpandListItem;
