import React, { useState } from "react";
import { Fragment } from "react";
import Navigation from "../Layout/Navigation";
import Footer from "../Layout/Footer";
import BackToTop from "../Reusable/BackToTop";
import Button from "../Reusable/Button";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import "../../../assets/global.scss";

const ThemeWrapper = (props) => {
  const { t } = useTranslation();
  const [showBlock, setShowBlock] = useState(false);
  const { pathname } = useLocation();
  return (
    <Fragment>
      <Navigation />
      <div className="main-content-site-holder">{props.children}</div>
      <BackToTop />
      {pathname != "/penetraciono-testiranje" &&
        pathname != "/ibm-guardium-landing" &&
        pathname != "/tactical-threat-intelligence" && (
          <>
            <div className="des-block">
              <Button
                text={t("cyberButton")}
                nameClass={`custom-buttom-hero`}
                link={"cyber-security"}
              />
            </div>
            <div className="mob-block">
              <div className={`cyber-mobile-block ${showBlock && "show"}`}>
                <div
                  className="cyber-mobile-block-text"
                  onClick={() => setShowBlock(false)}
                >
                  <Link to="/cyber-security">Da li ste sajber bezbedni?</Link>
                </div>
                <div
                  className="cyber-security-icon-holder"
                  onClick={() => setShowBlock(!showBlock)}
                >
                  <span>?</span>
                  <img
                    src="/images/cyber-icon.svg"
                    className="img-fluid"
                    alt="Oktacron"
                  />
                </div>
              </div>
            </div>
          </>
        )}
      <Footer />
    </Fragment>
  );
};

export default ThemeWrapper;
