import React, { useRef, useEffect } from "react";

const Video = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;

    const playVideo = () => {
      if (video) {
        video.play().catch((error) => {
          // Uhvatite greške ako automatska reprodukcija ne uspije
          console.error("Autoplay failed:", error);
        });
      }
    };

    // Pokušajte automatski pokrenuti video nakon učitavanja stranice
    playVideo();

    // Dodajte event listener koji će pokušati ponovno pokrenuti video kada korisnik interagira s stranicom
    const interactionHandler = () => {
      playVideo();
      // Uklonite event listenere nakon prve interakcije
      document.removeEventListener("touchstart", interactionHandler);
      document.removeEventListener("click", interactionHandler);
    };

    document.addEventListener("touchstart", interactionHandler);
    document.addEventListener("click", interactionHandler);

    // Cleanup funkcija (ukloni event listenere ako je komponenta unmountana prije interakcije)
    return () => {
      document.removeEventListener("touchstart", interactionHandler);
      document.removeEventListener("click", interactionHandler);
    };
  }, []); // Prazno polje označava da se useEffect izvršava samo jednom nakon montaže komponente

  return (
    <video
      ref={videoRef}
      id="background-video"
      autoPlay
      loop
      muted
      playsInline
      poster="/images/hero-image.png"
    >
      <source src="/images/hero-video.mp4" type="video/mp4" />
    </video>
  );
};

export default Video;
