import React from "react";
import Button from "./Button";
import ButtonIcon from "./ButtonIcon";

const BlockCustomImageTextLanding = ({
  title2,
  title3,
  title4,
  list1,
  list2,
  list3,
  list4,
  list5,
  btn,
  image,
  btnLink,
}) => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="block-custom-image-text-landing-holder">
          <div className="block-custom-image-text-landing-content">
            <div className="block-custom-image-text-landing-content-image">
              <img src={image} className="img-fluid" alt="Oktacron" />
            </div>
            <div className="block-custom-image-text-landing-content-text">
              <div className="block-custom-image-text-landing-content-text-title">
                {title2}
              </div>
              <div className="block-custom-image-text-landing-content-text-list">
                <div className="block-custom-image-text-ladingn-text">
                  {title3}
                </div>
                <div className="block-custom-image-text-ladingn-list-content">
                  <ul>
                    {list1 && <li>{list1}</li>}
                    {list2 && <li>{list2}</li>}
                    {list3 && <li>{list3}</li>}
                    {list4 && <li>{list4}</li>}
                    {list5 && <li>{list5}</li>}
                  </ul>
                </div>
                <div className="block-custom-image-text-ladingn-text">
                  {title4}
                </div>
              </div>
              <div className="block-custom-image-text-landing-content-text-button">
                <ButtonIcon
                  documentName={btnLink}
                  nameClass={`custom-buttom-gold`}
                  text={btn}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlockCustomImageTextLanding;
