import React from "react";
import Button from "../../Reusable/Button";

const SecuritySection = ({ t, link }) => {
  return (
    <div className="security-section-holder">
      <div className="container">
        <div className="row">
          <div className="security-section-holder-content">
            <div className="security-section-image"></div>
            <div className="security-section-content">
              <h2>{t("home.security.title")}</h2>
              <h3>{t("home.security.desc")}</h3>
              <Button
                text={t("home.security.button")}
                nameClass={`custom-security-button`}
                link={link}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecuritySection;
