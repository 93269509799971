import React from "react";
import Button from "./Button";

const BlockImageElements = ({
  img,
  title,
  description,
  link,
  buttonTitle = "",
}) => {
  const imageStyle = {
    backgroundImage: `url(${require(`../../../assets/Media/${img}`)})`,
  };

  return (
    <div className="block-image-item-holder" style={imageStyle}>
      <div className="block-image-main-block-content">
        <div className="block-image-item">
          <h2>{title}</h2>
          <p>{description}</p>
          <Button
            text={buttonTitle}
            link={link}
            nameClass={`custom-buttom-gold`}
          />
        </div>
      </div>
    </div>
  );
};

export default BlockImageElements;
