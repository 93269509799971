import {
  sentContactFormPath,
  getClientsListItems,
  getQaListItems,
  getTeamListItems,
  getSeoListItems,
  getCaseStudyListItems,
  getDocumentsListItems,
  getDocumentsAllListItems,
  getTestimonialsListItems,
  getResourcesAllListItems,
  getBlogAllListItems,
} from "../../constants/apiRoutes";
import { globalActions } from "../../reducers/Site/globalActions";
import apiService from "../../utils/configAxios";
import i18next from "i18next";

export const fetchContactFormSent = (
  dataValues,
  showMessageContact,
  navigate
) => {
  return (dispatch) => {
    return apiService
      .post(sentContactFormPath(), dataValues)
      .then((response) => {
        showMessageContact();
        console.log(response, "respo");
      })
      .catch((err) => {
        navigate("", { replace: true });
        console.log(err, "err");
      });
  };
};

export const fetchClientsListItems = () => {
  return (dispatch) => {
    dispatch(globalActions.actionStart());
    return apiService
      .get(getClientsListItems())
      .then((response) => {
        dispatch(globalActions.fetchItemsListClients(response.data));
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
};

export const fetchQaListItems = (type) => {
  return (dispatch) => {
    return apiService
      .get(getQaListItems(i18next.language, type))
      .then((response) => {
        dispatch(globalActions.fetchItemsListQa(response.data));
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
};

export const fetchTeamListItems = () => {
  return (dispatch) => {
    return apiService
      .get(getTeamListItems(i18next.language))
      .then((response) => {
        dispatch(globalActions.fetchItemsListTeam(response.data));
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
};

export const fetchSeoListItems = ($page) => {
  return (dispatch) => {
    return apiService
      .get(getSeoListItems($page))
      .then((response) => {
        dispatch(globalActions.fetchItemsListSeo(response.data));
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
};

export const fetchCaseStudyListItems = () => {
  return (dispatch) => {
    return apiService
      .get(getCaseStudyListItems(i18next.language))
      .then((response) => {
        dispatch(globalActions.fetchItemsListCaseStudy(response.data));
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
};

export const fetchDocumentsListItems = () => {
  return (dispatch) => {
    return apiService
      .get(getDocumentsListItems(i18next.language))
      .then((response) => {
        dispatch(globalActions.fetchItemsListDocuments(response.data));
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
};

export const fetchTestimonialsListItems = () => {
  return (dispatch) => {
    return apiService
      .get(getTestimonialsListItems(i18next.language))
      .then((response) => {
        dispatch(globalActions.fetchItemsListTestimonials(response.data));
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
};

export const fetchDocumentsAllListItems = () => {
  return (dispatch) => {
    return apiService
      .get(getDocumentsAllListItems(i18next.language))
      .then((response) => {
        dispatch(globalActions.fetchItemsListDocumentsAll(response.data));
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
};

export const fetchResourcesAllListItems = () => {
  return (dispatch) => {
    return apiService
      .get(getResourcesAllListItems(i18next.language))
      .then((response) => {
        dispatch(globalActions.fetchItemsListResourcesAll(response.data));
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
};

export const fetchBlogListItems = (id = 0) => {
  return (dispatch) => {
    return apiService
      .get(getBlogAllListItems(i18next.language, id))
      .then((response) => {
        dispatch(globalActions.fetchItemsListBlogAll(response.data));
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
};
