import React from "react";

const BlockInfoDescription = ({ text, image }) => {
  const formattedText = String.raw`${text}`;
  return (
    <div className="cyber-info-hero-holder-content special-block-info-description-landing">
      <div className="container-fluid">
        <div className="row">
          <div className="cyber-info-hero-holder">
            {formattedText.split("\n").map((value, index) => (
              <h2
                key={index}
                dangerouslySetInnerHTML={{
                  __html: value,
                }}
              ></h2>
            ))}

            {image && (
              <div className="image-holder-cyber-info-hero-holder">
                <img
                  src={`/images/${image}`}
                  className="img-fluid"
                  alt="Oktacron"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlockInfoDescription;
