import React from "react";

const ImagesBlock = ({ t }) => {
  return (
    <div className="images-block-content-holder">
      <div className="container-fluid">
        <div className="row">
          <div className="images-block-body">
            <div className="images-block-left-holder images-block-holder">
              <h2>{t("soc.block-1-title")}</h2>
              <p>{t("soc.block-1-desc")}</p>
            </div>
            <div className="images-block-right-holder images-block-holder">
              <h2>{t("soc.block-2-title")}</h2>
              <p>{t("soc.block-2-desc")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImagesBlock;
