import React from "react";
import Button from "../../Reusable/Button";

const SolutionsItem = ({ img, title, description, link, buttonTitle }) => {
  const imageStyle = {
    backgroundImage: `url(${require(`../../../../assets/Media/${img}`)})`,
  };

  return (
    <div className="solutions-item-holder" style={imageStyle}>
      <div className="solutions-item-main-block-content">
        <div className="solution-item">
          <h2>{title}</h2>
          <p>{description}</p>
          <Button
            text={buttonTitle}
            nameClass={`custom-buttom-gold`}
            link={link}
          />
        </div>
      </div>
    </div>
  );
};

export default SolutionsItem;
