import React, { useEffect, useState } from "react";

const Hero = ({ title, img, color = "#fff", maxWidth = "normal" }) => {
  const imageStyle = {
    backgroundImage: `url(${require(`../../../assets/Media/${img}`)})`,
  };

  const [hasReachedSection, setHasReachedSection] = useState(
    "show-first-animation"
  );

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    const threshold = 50;
    if (scrollTop < threshold) {
      setHasReachedSection("show-animation");
    } else {
      setHasReachedSection("");
    }
  };

  return (
    <div className="service-hero-holder-content" style={imageStyle}>
      <div className="container-fluid">
        <div className="row">
          <div
            className={`service-hero-holder ${hasReachedSection} ${
              maxWidth == "large" && "hero-title-large"
            }`}
          >
            <span class={`block ${hasReachedSection}`}></span>
            <h1
              style={{ color: `${color}` }}
              dangerouslySetInnerHTML={{
                __html: title,
              }}
            ></h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
