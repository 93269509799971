import React from "react";
import { baseUrl } from "../../../../utils";

const TeamItem = ({ item, key }) => {
  return (
    <div className="team-list-item" key={key}>
      <div className="team-item-holder">
        <div className="team-item-image">
          <img
            src={baseUrl + "images/" + item.acImage}
            className="img-fluid"
            alt={item.acName}
          />
        </div>
        <div className="team-item-name">{item.acName}</div>
        <div className="team-item-position">{item.acPosition}</div>
        <div
          className="team-item-description"
          dangerouslySetInnerHTML={{
            __html: item?.acDescription,
          }}
        />
        <div className="team-item-line"></div>
        <div className="team-item-social"></div>
      </div>
    </div>
  );
};

export default TeamItem;
