import React from "react";

const BlockSpecialItemNumberLanding = ({ title, subtitle, number }) => {
  return (
    <div className="block-bckg-item-full-width">
      <div className="container-fluid block-special-item-number-landing-main">
        <div className="row">
          <div className="block-special-item-number-landing-holder">
            <h1
              dangerouslySetInnerHTML={{
                __html: title,
              }}
            />
            <h2
              dangerouslySetInnerHTML={{
                __html: subtitle,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlockSpecialItemNumberLanding;
