import React from "react";
import { Fragment } from "react";
import Button from "./Button";

const TitleDescriptionLanding = (props) => {
  const { title, description, button, link, id = "oktacron-id" } = props || {};

  const formattedTitle = String.raw`${title}`;
  const formattedDescription = description ? String.raw`${description}` : "";

  return (
    <div className="container">
      <div className="row">
        <div
          className="title-description-holder-content title-description-holder-content-landing"
          id={id}
        >
          <div className="title-description-body">
            <h2>
              {formattedTitle.split("\n").map((value, index) => (
                <Fragment key={index}>
                  {value}
                  <br />
                </Fragment>
              ))}
            </h2>
            {formattedDescription &&
              formattedDescription.split("\n").map((value, index) => (
                <p
                  key={index}
                  dangerouslySetInnerHTML={{
                    __html: value,
                  }}
                ></p>
              ))}
            {button && (
              <div className="button-holder-item-description">
                <Button
                  link={link}
                  text={button}
                  nameClass={`custom-buttom-gold`}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TitleDescriptionLanding;
