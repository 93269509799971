import React from "react";
import { useTranslation } from "react-i18next";
import TitleDesctiptionLandingWithImage from "./TitleDesctiptionLandingWithImage";
import BlockCustomImageTextLanding from "./BlockCustomImageTextLanding";

const BlockItemTitleWithImageBlock = ({
  title,
  desc,
  title2,
  title3,
  list1,
  list2,
  list3,
  list4,
  list5,
  btn,
  link,
  image,
}) => {
  const { t } = useTranslation();

  return (
    <div className="block-title-desc-image-holder">
      <TitleDesctiptionLandingWithImage title={title} description={desc} />
      <BlockCustomImageTextLanding
        image={image}
        title2={title2}
        title3={title3}
        // title4={t("penetrationTestingLandingPage.imageTextTitleBlock.title4")}
        list1={list1}
        list2={list2}
        list3={list3}
        list4={list4}
        list5={list5}
        btn={btn}
        btnLink={link}
      />
    </div>
  );
};

export default BlockItemTitleWithImageBlock;
