import React from "react";

const RelatedServicesImageLanding = ({
  image,
  title,
  desc,
  key1,
  key2,
  key3,
  value1,
  value2,
  value3,
  keySize = "normal",
}) => {
  const imageStyle = {
    backgroundImage: `url(${require(`../../../assets/Media/${image}`)})`,
  };

  return (
    <div
      className="related-services-holder-content related-service-image-holder-landing"
      style={imageStyle}
    >
      <div className="related-services-content-center">
        <h3>{title}</h3>
        <h4>{desc}</h4>
        <div className="related-services-list-items">
          <div className="related-services-item">
            <div
              className={`related-services-item-title ${
                keySize == "small" && "related-services-item-title-small"
              }`}
            >
              {key1}
            </div>
            <div className="related-services-item-desc">{value1}</div>
          </div>
          <div className="related-services-item">
            <div
              className={`related-services-item-title related-services-item-title-special-title ${
                keySize == "small" && "related-services-item-title-small"
              }`}
            >
              {key2}
            </div>
            <div className="related-services-item-desc">{value2}</div>
          </div>
          <div className="related-services-item">
            <div
              className={`related-services-item-title ${
                keySize == "small" && "related-services-item-title-small"
              }`}
            >
              {key3}
            </div>
            <div className="related-services-item-desc">{value3}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RelatedServicesImageLanding;
