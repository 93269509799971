import React from "react";
import { useTranslation } from "react-i18next";

const ImageBlock = ({ img }) => {
  const { t } = useTranslation();
  return (
    <div className="image-block-content">
      <div className="image-block-holder">
        <div className="image-block-holder-items des-block">
          <div className="image-block-holder-item-center">
            <div className="image-block-holder-item-1 image-block-holder-item">
              <div className="tekst">{t("socPage.text-1")}</div>
              <div className="image-block-holder-item-image">
                <img
                  src="/images/soc-item-1.svg"
                  className="img-fluid"
                  alt="Oktacron"
                />
              </div>
            </div>
            <div className="image-block-holder-item-2 image-block-holder-item">
              <div className="tekst">{t("socPage.text-2")}</div>
              <div className="image-block-holder-item-image">
                <img
                  src="/images/soc-item-2.svg"
                  className="img-fluid"
                  alt="Oktacron"
                />
              </div>
            </div>
            <div className="image-block-holder-item-3 image-block-holder-item">
              <div className="tekst">{t("socPage.text-3")}</div>
              <div className="image-block-holder-item-image">
                <img
                  src="/images/soc-item-3.svg"
                  className="img-fluid"
                  alt="Oktacron"
                />
              </div>
            </div>
            <div className="image-block-holder-item-4 image-block-holder-item">
              <div className="tekst">{t("socPage.text-4")}</div>
              <div className="image-block-holder-item-image">
                <img
                  src="/images/soc-item-4.svg"
                  className="img-fluid"
                  alt="Oktacron"
                />
              </div>
            </div>
            <div className="image-block-holder-item-5 image-block-holder-item">
              <div className="tekst">{t("socPage.text-5")}</div>
              <div className="image-block-holder-item-image">
                <img
                  src="/images/soc-item-5.svg"
                  className="img-fluid"
                  alt="Oktacron"
                />
              </div>
            </div>
            <div className="image-block-holder-item-6 image-block-holder-item">
              <div className="tekst">{t("socPage.text-6")}</div>
              <div className="image-block-holder-item-image">
                <img
                  src="/images/soc-item-6.svg"
                  className="img-fluid"
                  alt="Oktacron"
                />
              </div>
            </div>
            <div className="image-block-holder-item-7 image-block-holder-item">
              <div className="tekst">{t("socPage.text-7")}</div>
              <div className="image-block-holder-item-image">
                <img
                  src="/images/soc-item-7.svg"
                  className="img-fluid"
                  alt="Oktacron"
                />
              </div>
            </div>
            <div className="image-block-holder-item-8 image-block-holder-item">
              <div className="tekst">{t("socPage.text-8")}</div>
              <div className="image-block-holder-item-image">
                <img
                  src="/images/soc-item-8.svg"
                  className="img-fluid"
                  alt="Oktacron"
                />
              </div>
            </div>
            <div className="title-item-center-soc-text">
              {t("socPage.text-center")}
            </div>
            <img
              src="/images/soc-item-center.png"
              className="img-fluid"
              alt="Oktacron"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageBlock;
