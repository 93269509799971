import React from "react";
import HelmetSeo from "../../components/Site/Reusable/HelmetSeo";
import Hero from "../../components/Site/Reusable/Hero";
import SpacingItem from "../../components/Site/Reusable/SpacingItem";
import TitleDescription from "../../components/Site/Reusable/TitleDescription";
import ExapandList from "../../components/Site/Sections/Expertise/ExpandList";

import "../../assets/expertise.scss";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import i18next from "i18next";
import {
  fetchDocumentsListItems,
  fetchSeoListItems,
} from "../../actions/Site/global";
import { useEffect } from "react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

const Expertise = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { dataPagesListDocuments, dataPagesListSeo } = useSelector(
    (state) => state.globalReducer
  );

  useEffect(() => {
    dispatch(fetchSeoListItems(15));
  }, [dispatch]);

  const [currentLanguage, setCurrentLanguage] = useState(i18next.language);
  const [startLang, setStartLang] = useState(0);

  useEffect(() => {
    dispatch(fetchDocumentsListItems());

    const languageChangeHandler = (newLanguage) => {
      setCurrentLanguage(newLanguage);
    };

    // Pretplatite se na događaj promene jezika
    i18next.on("languageChanged", languageChangeHandler);

    setStartLang(1);

    // Očistite pretplatu kada se komponenta odmontira
    return () => {
      i18next.off("languageChanged", languageChangeHandler);
    };
  }, [dispatch]);

  useEffect(() => {
    if (startLang > 0) dispatch(fetchDocumentsListItems());
  }, [currentLanguage]);

  return (
    <div className="expertise-main">
      <HelmetSeo
        title={dataPagesListSeo.length > 0 && dataPagesListSeo[0].acTitle}
        description={
          dataPagesListSeo.length > 0 && dataPagesListSeo[0].acDescription
        }
        keywords={dataPagesListSeo.length > 0 && dataPagesListSeo[0].acKeywords}
      />
      <Hero
        title={t("eksperise.title")}
        img={`eksperiza-hero.jpg`}
        color={`#0A0A50`}
      />
      <SpacingItem />
      {dataPagesListDocuments?.map((item, key) => (
        <Fragment key={key}>
          <TitleDescription title={item.acCategoryName} />
          <ExapandList expandList={item.children} />
        </Fragment>
      ))}
    </div>
  );
};

export default Expertise;
