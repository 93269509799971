import React from 'react'
import { Fragment } from 'react';

const ParagraphRenderSpacing = ({ text }) => {

    const formattedText = String.raw`${text}`;

    return (
        <Fragment>
            {formattedText.split('\n').map((value, index) => (
                <Fragment key={index}>
                    {value}
                    <br />
                </Fragment>
            ))}
        </Fragment>
    )
}

export default ParagraphRenderSpacing