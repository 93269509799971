import React, { Fragment, useEffect } from "react";
import HelmetSeo from "../../components/Site/Reusable/HelmetSeo";

import "../../assets/tehnology-solutions.scss";
import Hero from "../../components/Site/Reusable/Hero";
import SpacingItem from "../../components/Site/Reusable/SpacingItem";
import CyberInfo from "../../components/Site/Reusable/CyberInfo";
import Solutions from "../../components/Site/Sections/TehnologySolutions/Solutions";
import OurPartners from "../../components/Site/Sections/Home/OurPartners";
import InfoBlock from "../../components/Site/Sections/TehnologySolutions/InfoBlock";
import ContactForm from "../../components/Site/Sections/ContactForm";
import RelatedServices from "../../components/Site/Reusable/RelatedServices";
import TitleDescription from "../../components/Site/Reusable/TitleDescription";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchSeoListItems } from "../../actions/Site/global";

const TehnologySolutions = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { dataPagesListSeo } = useSelector((state) => state.globalReducer);

  useEffect(() => {
    dispatch(fetchSeoListItems(3));
  }, [dispatch]);

  const flickityOptions = {
    groupCells: 1, // Prikazuje 5 slika po slajdu
    pageDots: false, // Sakrij tačke za navigaciju
    wrapAround: true, // Omogućava beskonačno pomjeranje
    initialIndex: 1,
  };

  return (
    <Fragment>
      <HelmetSeo
        title={dataPagesListSeo.length > 0 && dataPagesListSeo[0].acTitle}
        description={
          dataPagesListSeo.length > 0 && dataPagesListSeo[0].acDescription
        }
        keywords={dataPagesListSeo.length > 0 && dataPagesListSeo[0].acKeywords}
      />
      <Hero title={t("tehnologies.title")} img={`tehno-hero.png`} />
      <SpacingItem />
      <CyberInfo text={t("tehnologies.desc")} />
      <TitleDescription title={t("tehnologies.title2")} />
      <Solutions t={t} />
      <InfoBlock
        title={t("tehnologies.title3")}
        desc={t("tehnologies.desc3")}
        buttonTitle={t("tehnologies.button3")}
        link={'services'}
      />
      <OurPartners t={t} flickityOptions={flickityOptions} />
      <RelatedServices
        title={t("teh.title")}
        text1={t("teh.title1")}
        link1={t("teh.link1")}
        text2={t("teh.title2")}
        link2={t("teh.link2")}
        text3={t("teh.title3")}
        link3={t("teh.link3")}
        buttonTitle={t("teh.button")}
      />
      <ContactForm t={t} />
    </Fragment>
  );
};

export default TehnologySolutions;
