import React, { useState } from "react";

const SiemService = ({ t }) => {
  const [showActive, setShowActive] = useState(null);

  return (
    <div className="service-our-service-item-holder-content" id="aspen">
      <div className="container-fluid">
        <div className="row">
          <div className="service-our-service-item-holder">
            <div className="service-our-service-item">
              <div
                className="container-serive-image-text-block"
                style={{ backgroundImage: "url(/images/siem-res.png)" }}
              >
                <h1
                  dangerouslySetInnerHTML={{
                    __html: t("siem.aspen.title"),
                  }}
                />
                {t("siem.aspen.desc")
                  .split("\n")
                  .map((tekst, indeks) => (
                    <p
                      key={indeks}
                      dangerouslySetInnerHTML={{
                        __html: tekst,
                      }}
                    />
                  ))}
              </div>
              <div className="content-our-service-content-holder">
                <h2>{t("siem.aspen.title2")}</h2>

                <div className="guardian-holder-content">
                  <div className="guardian-holder-content-left">
                    <ul className="list-items-guardian-lists">
                      <li className={`${showActive === 1 && "active"}`}>
                        {t("siem.aspen.li-1")}
                      </li>
                      <li className={`${showActive === 2 && "active"}`}>
                        {t("siem.aspen.li-2")}
                      </li>
                      <li className={`${showActive === 3 && "active"}`}>
                        {t("siem.aspen.li-3")}
                      </li>
                      <li className={`${showActive === 4 && "active"}`}>
                        {t("siem.aspen.li-4")}
                      </li>
                      <li className={`${showActive === 5 && "active"}`}>
                        {t("siem.aspen.li-5")}
                      </li>
                    </ul>
                  </div>
                  <div className="guardian-holder-content-right">
                    <div className="guardian-holder-content-circle">
                      <div className="guardin-holder-content-main-circle">
                        SIEM
                        <div
                          className="guardin-holder-content-circle guardin-holder-content-circle-1"
                          onMouseEnter={() => setShowActive(1)}
                          onMouseLeave={() => setShowActive(null)}
                        >
                          {t("siemCircle.bigData")}
                        </div>
                        <div
                          className="guardin-holder-content-circle guardin-holder-content-circle-3"
                          onMouseEnter={() => setShowActive(3)}
                          onMouseLeave={() => setShowActive(null)}
                        >
                          {t("siemCircle.digital")}
                        </div>
                        <div
                          className="guardin-holder-content-circle guardin-holder-content-circle-2"
                          onMouseEnter={() => setShowActive(2)}
                          onMouseLeave={() => setShowActive(null)}
                        >
                          {t("siemCircle.visual")}
                        </div>
                        <div
                          className="guardin-holder-content-circle guardin-holder-content-circle-4"
                          onMouseEnter={() => setShowActive(4)}
                          onMouseLeave={() => setShowActive(null)}
                        >
                          {t("siemCircle.incident")}
                        </div>
                        <div
                          className="guardin-holder-content-circle guardin-holder-content-circle-5"
                          onMouseEnter={() => setShowActive(5)}
                          onMouseLeave={() => setShowActive(null)}
                        >
                          {t("siemCircle.trap")}
                        </div>
                      </div>
                    </div>
                    {/* <img src='/images/siem-image.png' className='img-fluid' alt='Oktacron' /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SiemService;
