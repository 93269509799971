import React from "react";
import { Fragment } from "react";
import HelmetSeo from "../../components/Site/Reusable/HelmetSeo";
import Hero from "../../components/Site/Sections/Home/Hero";
import Security from "../../components/Site/Sections/Home/Security";
import Shields from "../../components/Site/Sections/Home/Shields";
import Soc from "../../components/Site/Sections/Home/Soc";
import Clients from "../../components/Site/Sections/Home/Clients";
import WhyOktacron from "../../components/Site/Sections/Home/WhyOktacron";
import OurPartners from "../../components/Site/Sections/Home/OurPartners";
import InfoLine from "../../components/Site/Sections/Home/InfoLine";
import ContactForm from "../../components/Site/Sections/ContactForm";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { fetchSeoListItems } from "../../actions/Site/global";
import { useEffect } from "react";

function Home() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { dataPagesListSeo } = useSelector((state) => state.globalReducer);

  useEffect(() => {
    dispatch(fetchSeoListItems(1));
  }, [dispatch]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const whyBlock = [
    {
      value: t("home.whyOkta.item1_title"),
      text: t("home.whyOkta.item1_desc"),
    },
    {
      value: t("home.whyOkta.item2_title"),
      text: t("home.whyOkta.item2_desc"),
    },
    {
      value: t("home.whyOkta.item3_title"),
      text: t("home.whyOkta.item3_desc"),
    },
    {
      value: t("home.whyOkta.item4_title"),
      text: t("home.whyOkta.item4_desc"),
    },
  ];

  const flickityOptions = {
    groupCells: 1, // Prikazuje 5 slika po slajdu
    pageDots: false, // Sakrij tačke za navigaciju
    wrapAround: true, // Omogućava beskonačno pomjeranje
    initialIndex: 1,
  };

  return (
    <Fragment>
      <HelmetSeo
        title={dataPagesListSeo.length > 0 && dataPagesListSeo[0].acTitle}
        description={
          dataPagesListSeo.length > 0 && dataPagesListSeo[0].acDescription
        }
        keywords={dataPagesListSeo.length > 0 && dataPagesListSeo[0].acKeywords}
      />
      <Hero />
      <Security link={`about-us`} t={t} />
      <Shields link={`services`} t={t} />
      <Soc link={`soc`} t={t} />
      <Clients />
      <WhyOktacron
        buttonTitle={t("home.whyOkta.button")}
        title={t("home.whyOkta.title")}
        description={t("home.whyOkta.description")}
        img={`why-image.png`}
        items={whyBlock}
        link={`expertise`}
      />
      <OurPartners
        t={t}
        settings={settings}
        flickityOptions={flickityOptions}
      />
      <InfoLine settings={settings} />
      <ContactForm t={t} />
    </Fragment>
  );
}

export default Home;
