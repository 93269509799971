import React from 'react'

const BackToTop = () => {

    const handleBackToTopClick = () => {
        // Pomeranje na vrh stranice sa animacijom
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Dodajte 'smooth' za glatki scroll
        });
    };

    return (
        <div className='back-to-top-holder' onClick={handleBackToTopClick}>
            <img src='/images/backtotop.png' className='img-fluid' alt='Oktacron' />
        </div>
    )
}

export default BackToTop