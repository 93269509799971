import React from 'react'

const FourBlocks = ({ items }) => {
    return (
        <div className='four-blocks-holder-content'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='four-blocks-items-list'>
                        {items?.map((value, index) => (
                            <div className='four-blocks-item' key={index}>
                                <h3>{value.title}</h3>
                                <p>{value.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FourBlocks