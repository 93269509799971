import React, { Fragment } from "react";
import HelmetSeo from "../../components/Site/Reusable/HelmetSeo";
import Hero from "../../components/Site/Reusable/Hero";
import SpacingItem from "../../components/Site/Reusable/SpacingItem";
import CyberInfo from "../../components/Site/Reusable/CyberInfo";
import TitleDescription from "../../components/Site/Reusable/TitleDescription";
import ImageBlock from "../../components/Site/Reusable/ImageBlock";
import TextButtonImageBckg from "../../components/Site/Reusable/TextButtonImageBckg";
import ImagesBlock from "../../components/Site/Sections/SOC/ImagesBlock";
import FourBlocks from "../../components/Site/Sections/SOC/FourBlocks";
import BlockImageElements from "../../components/Site/Reusable/BlockImageElements";

import "../../assets/soc.scss";
import RelatedServices from "../../components/Site/Reusable/RelatedServices";
import ExapandList from "../../components/Site/Sections/SOC/ExapandList";
import { fetchQaListItems, fetchSeoListItems } from "../../actions/Site/global";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

const Soc = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { dataPagesListQa, dataPagesListSeo } = useSelector(
    (state) => state.globalReducer
  );

  const [currentLanguage, setCurrentLanguage] = useState(i18next.language);
  const [startLang, setStartLang] = useState(0);

  useEffect(() => {
    dispatch(fetchQaListItems(2));
    dispatch(fetchSeoListItems(4));

    const languageChangeHandler = (newLanguage) => {
      setCurrentLanguage(newLanguage);
    };

    // Pretplatite se na događaj promene jezika
    i18next.on("languageChanged", languageChangeHandler);

    setStartLang(1);

    // Očistite pretplatu kada se komponenta odmontira
    return () => {
      i18next.off("languageChanged", languageChangeHandler);
    };
  }, [dispatch]);

  useEffect(() => {
    if (startLang > 0) dispatch(fetchQaListItems(2));
  }, [currentLanguage]);

  const blocks = [
    {
      title: t("soc.box-1-title"),
      description: t("soc.box-1-desc"),
    },
    {
      title: t("soc.box-2-title"),
      description: t("soc.box-2-desc"),
    },
    {
      title: t("soc.box-3-title"),
      description: t("soc.box-3-desc"),
    },
    {
      title: t("soc.box-4-title"),
      description: t("soc.box-4-desc"),
    },
  ];

  return (
    <Fragment>
      <HelmetSeo
        title={dataPagesListSeo.length > 0 && dataPagesListSeo[0].acTitle}
        description={
          dataPagesListSeo.length > 0 && dataPagesListSeo[0].acDescription
        }
        keywords={dataPagesListSeo.length > 0 && dataPagesListSeo[0].acKeywords}
      />
      <Hero title={t("soc.title")} img={`soc-hero.png`} />
      <SpacingItem />
      <CyberInfo text={t("soc.desc")} />
      <TitleDescription title={t("soc.title2")} description={t("soc.desc2")} />
      <ImageBlock img={`logovi.png`} />
      <TextButtonImageBckg
        description={t("soc.desc3")}
        buttonTitle={t("soc.button3")}
        img={`soc1.png`}
        link={`about-us#eksperti`}
      />
      <TitleDescription title={t("soc.title4")} description={t("soc.desc4")} />
      <ImagesBlock t={t} />
      <TitleDescription title={t("soc.title5")} description={t("soc.desc5")} />
      <FourBlocks items={blocks} />
      <BlockImageElements
        img={`soc2.png`}
        title={t("soc.title6")}
        description={t("soc.desc6")}
        buttonTitle={t("soc.button6")}
        link={`siem`}
      />
      <RelatedServices
        title={t("related.title")}
        text1={t("related.soc.title1")}
        link1={t("related.soc.link1")}
        text2={t("related.soc.title2")}
        link2={t("related.soc.link2")}
        text3={t("related.soc.title3")}
        link3={t("related.soc.link3")}
        buttonTitle={t("related.button")}
      />
      <TitleDescription title={t("soc.faq-title")} />
      <ExapandList dataList={dataPagesListQa} />
    </Fragment>
  );
};

export default Soc;
